import classes from './PageHeading.module.scss'
import Icon from '../../../Icon'


interface PageHeadingProps {
  title: string
  description?: string
  icon?: string
  divider?: boolean
}

const PageHeading = ({title, description, icon, divider}: PageHeadingProps) => {
  
  return (
    <div className={classes.header}>
      <div className={classes.heading}>
        {
          icon &&
          <span className={classes.icon} >
            <Icon name={icon} size='32' />
          </span>
        }
        { title }
      </div>
      {
        description &&
        <div className={classes.description}>{ description } </div>
      }
      {divider && <hr className={classes.divider}/>}
    </div>
  )
}

export default PageHeading