import { Link } from 'react-router-dom'
import classes from './Footer.module.scss'
import Icon from '../../../Icon'

const Footer = () => {
  return (
    <div className={classes.footer}>
      <div className={classes.links}>
        <div className={classes.copyright}>{"© 2023 HaystackID"}</div>
        <div className={classes.separator}>|</div>
        <div>
          <a className="btn" target='_blank' rel="noreferrer" href="https://haystackid.com">Company</a>|
          <a className="btn" target='_blank' rel="noreferrer" href="https://haystackid.com/support">Support</a>|
          <a className="btn" target='_blank' rel="noreferrer" href="https://haystackid.com/privacy">Privacy Policy</a>|
          <a className="btn" target='_blank' rel="noreferrer" href="https://haystackid.com/privacy-us">US Privacy Policy</a>
        </div>
      </div>
      <div className={classes.haysatck_button}>
        <div className={classes.haystack_logo}>
          <img src={'https://haystackid.com/wp-content/uploads/2021/11/hsid_logo_reg.svg'} alt="" />
        </div>
        <a className="btn" href="https://haystackid.com/review-right/">
          <Icon name='logoutRoundedLeft' color='#FFF' size='18' />
          Back to HaystackID.com
        </a>
      </div>
    </div>

  )
}

export default Footer