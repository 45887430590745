import { useEffect, useState, useRef } from 'react'
import useAssessment from '../../../customHooks/useAssessment'
import { useForm } from 'react-hook-form'
import { useNavigate } from "react-router-dom"
import PageHeading from '../../shared/PageHeading'
import ProgressBar from './ProgressBar'
import classes from './Assessment.module.scss'
import Button from '../../shared/ComponentLibrary/RButton'
import Icon from '../../../Icon'
import Timer from './Timer'
import PulseWheel from './PulseWheel'
import AssessmentImage from './AssessmentImage'
import { toast } from 'react-toastify'
import PracticeAssessmentFeedback from './PracticeAssessmentFeedback'
import { scrollDocToTop } from '../../../helpers/methods'
import { datadogLogs } from '@datadog/browser-logs'


const PracticeAssessment = () => {
  const navigate = useNavigate()
  const { startPracticeTest, submitPracticeTest } = useAssessment()
  const [error, setError] = useState('')
  const [assessmentData, setAssessmentData] = useState<IAssessmentData>({})
  const [pagesVisible, setPagesVisible] = useState<boolean[]>([])
  const [eligible, setEligible] = useState(false)
  const [documentNumber, setDocumentNumber] = useState(1)
  const [resetTimer, setResetTimer] = useState(false)
  const [pulseWheelTimeUp, setPulseWheelTimeUp] = useState(false)
  const { register, reset,  handleSubmit } = useForm()
  const scrollRef = useRef<any>()

  useEffect(() => startPracticeTest({setAssessmentData, setPagesVisible, setError}), [])
  useEffect(() => console.log(error), [error])
  useEffect(() => console.log(assessmentData), [assessmentData])

  const submitHandler = (data: any) => {
    let successHandler = (submitData: ISubmitResponseData) => {
      setDocumentNumber((current) => current + 1)
      reset()
      datadogLogs.logger.info('Practice Assessment: Document Questions Submitted', {documentNumberInAssessment: documentNumber, details: submitData});
      scrollDocToTop(scrollRef)
      setResetTimer((current) => !current )
    }
    let errorHandler = () => {
      console.log("practice assessment failed to submit")
    }
    if (assessmentData.legalDocument) {
      let submitData: ISubmitResponseData = {
        attributes: {
          legalDocumentId: assessmentData.legalDocument.id,
          response: []
        },
        setAssessmentData: setAssessmentData,
        setPagesVisible: setPagesVisible,
        setError: setError
      }
      Object.keys(data).forEach((key) => {
        if (data[key]) {
          submitData.attributes?.response.push({ questionId: parseInt(key), answer: data[key] === 'true' })
        }
      })
      console.log(submitData)
      
      submitPracticeTest(submitData, successHandler, errorHandler)
      
    }
  }

  const setOnePageAsVisible = (index: number) => {
    const withOnePageVisible = (currentPagesVisible: boolean[]) => currentPagesVisible.map((currentVal, currentIndex) => {
      if (currentIndex === index){
        return true;
      }
      return currentVal;
    });
    setPagesVisible(withOnePageVisible);
  }
  
  const readyToShowPages = () => Boolean(pagesVisible.length && pagesVisible.filter(x => x).length === assessmentData.legalDocument?.documentPages.length)
  const forceDoNotShowPages = () => Boolean(!readyToShowPages() || pulseWheelTimeUp)

  useEffect(() => {
    if (assessmentData.eligible?.status === false) {
      navigate('/')
      toast.info(assessmentData.eligible?.message)
    }
    else {
      setEligible(true)
      assessmentData.assessment?.documentNumber && setDocumentNumber(assessmentData.assessment.documentNumber)
    }
  }, [assessmentData.eligible])
  
  return (
    <div className={classes.assessment_container}>
      {
        assessmentData?.isCompleted ? 
        <PracticeAssessmentFeedback assessmentData={assessmentData} /> :
        eligible &&
        <div>
          <div className={classes.header}>
            <PageHeading title='Practice Assessment' icon='testResults' />
            { assessmentData.assessment && <Timer seconds={assessmentData.assessment?.secondsRemaining} showIcon/> }
          </div>
          <ProgressBar currentValue={documentNumber - 1} maxValue={3}/>

          <form onSubmit={handleSubmit(submitHandler)}>
            <div className={`${classes.assessment_section} ${forceDoNotShowPages() ? classes.pulse_wheel_holder : ''}`}>
              <PulseWheel show={!readyToShowPages()} style={{borderColor: "#2CB6D1", borderTopColor: "transparent"}} timer={60} timeUp={pulseWheelTimeUp} setTimeUp={setPulseWheelTimeUp}>
                <div>
                  <p>The connection appears to be unstable. Please try again later.</p>
                  <p>We recommend taking the assessment from a desktop computer with a stable connection.</p>
                </div>
              </PulseWheel>
              <div className={`${classes.title_section} ${forceDoNotShowPages() ? classes.hidden : ''}`}>
                <div className={classes.title}>
                  <div><b>Document {documentNumber}:</b></div>
                  "{ assessmentData.legalDocument && assessmentData.legalDocument.title }"
                </div>
                <div className={classes.timer}>
                  { assessmentData.assessment && <Timer seconds={assessmentData.assessment?.secondsElapsed} incremental resetTimer={resetTimer} /> }
                </div>
              </div>
              <hr className={classes.divider}/>

              <div className={`${classes.questions_section} ${forceDoNotShowPages() ? classes.hidden : ''}`}>
                <div className={classes.document_section} ref={scrollRef}>
                  <div className={classes.document}>
                    {
                      assessmentData.legalDocument?.documentPages.map((page, index) => {
                        return (
                          <div className={classes.document} key={index}>
                            <div className={classes.meta}>
                              <b>{page.meta}</b>
                            </div>
                            <div>
                              <AssessmentImage src={page.imageUrl} alt={page.meta} onComplete={() => {setOnePageAsVisible(index)}} />
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
                <div className={classes.questions}>
                  {
                    assessmentData.assessment && assessmentData.assessment.questions.map((question) => {
                      return (
                        <div className={classes.question} key={question.id}>
                          <div className={classes.text}>
                            <b>{question.id}. </b>
                            <div>{question.question}</div>
                          </div>
                          <div className={classes.options}>
                            <div>
                              <label>
                                <input
                                  type="radio"
                                  {...register(question.id.toString())}
                                  value="true"
                                  id={`question_${question.id}_true`}
                                  data-dd-privacy="allow"
                                /> Yes
                              </label>
                            </div>
                            <div>
                              <label>
                                <input
                                  type="radio"
                                  {...register(question.id.toString())}
                                  value="false"
                                  id={`question_${question.id}_false`}
                                  data-dd-privacy="allow"
                                /> No
                              </label>
                            </div>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
            <div className={classes.buttons}>
              <div className={classes.cancel_button} />
              <div className={classes.submit_button}>
                <Button type='submit' hidden={forceDoNotShowPages()}>
                  Next Document
                  <Icon name='longArrowLeft' color='#FFF' transform />
                </Button>  
              </div>
            </div>
          </form>
        </div>
      }
    </div>
  )
}

export default PracticeAssessment