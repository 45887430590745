import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import classes from './Assessment.module.scss'
import useAssessment from '../../../customHooks/useAssessment'
import { HtmlSafeElement } from '../../shared/HtmlSafeElement'
import Icon from '../../../Icon'
import Button from '../../shared/ComponentLibrary/RButton'
import PageHeading from '../../shared/PageHeading'
import BackToHomeButton from '../../shared/BackToHomeButton'


const AssessmentIntroduction = () => {
  const navigate = useNavigate()

  const { getIntroPage } = useAssessment()
  const [introPageData, setIntroPageData] = useState<IAssessmentIntroduction>()

  useEffect(() => {
    getIntroPage(1, setIntroPageData)
  }, [])

  const nextPage = () => navigate('/assessment/instructions')

  return(
    <div>
      <BackToHomeButton />
      <PageHeading title='Assessment Introduction' icon='testResults' divider />
      {
        introPageData &&
        <div className={classes.introduction}>
          <div className={classes.main_heading}>
            <HtmlSafeElement element="h4" content={introPageData.mainHeading}  />
            <HtmlSafeElement element="p" content={introPageData.mainHeadingContent}  />
          </div>
        </div>
      }
      <hr className={classes.divider}/>
      <div className={classes.navigation_buttons}>
        <div className={classes.back}>
        </div>
        <div className={classes.next}>
          <Button onClickMethod={nextPage}>
            Next
            <Icon name='longArrowLeft' color='#FFF' transform />
          </Button>
        </div>
      </div>
    </div>
  )
}

export default AssessmentIntroduction