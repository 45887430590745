type IconType = {
  [key: string]: {
    viewBox: string
    path: JSX.Element
  }
}

export const iconsList: IconType = {
  deleteSign: {
    viewBox: "0 0 50 50",
    path: <path d="M40.783 7.271a2 2 0 00-1.396.616L25.05 22.223 10.715 7.887a2 2 0 00-1.436-.608 2 2 0 00-1.392 3.436L22.223 25.05 7.887 39.387a2 2 0 102.828 2.828L25.05 27.879l14.336 14.336a2 2 0 102.828-2.828L27.879 25.05l14.336-14.336a2 2 0 00-1.432-3.444z"/>
  },
  bio: {
    viewBox: "0 0 100 100",
    path:
      <>
        <path fill="#2CB6D1" d="M72.9,8.1H27.1c-5.7,0-10.4,4.7-10.4,10.4v64.6c0,5.7,4.7,10.4,10.4,10.4h45.8c5.7,0,10.4-4.7,10.4-10.4V18.5  C83.3,12.8,78.7,8.1,72.9,8.1z"/>
        <path fill="#025a66" d="M64.9,60.2c-0.9,0-1.7-0.6-2-1.5c-1.9-6.6-7.1-11-12.9-11s-11,4.4-12.9,11c-0.3,1.1-1.5,1.7-2.6,1.4   c-1.1-0.3-1.7-1.5-1.4-2.6c2.4-8.4,9.2-14,16.9-14s14.5,5.6,16.9,14c0.3,1.1-0.3,2.3-1.4,2.6C65.3,60.1,65.1,60.2,64.9,60.2z"/>
        <path fill="#025a66" d="M50,41.4c-5.1,0-9.2-4.7-9.2-10.4s4.1-10.4,9.2-10.4s9.2,4.7,9.2,10.4S55.1,41.4,50,41.4z M50,24.8   c-2.8,0-5.1,2.8-5.1,6.2s2.3,6.2,5.1,6.2s5.1-2.8,5.1-6.2S52.8,24.8,50,24.8z"/>
        <path fill="#025a66" d="M66.7,72.7H33.3c-1.2,0-2.1-0.9-2.1-2.1c0-1.2,0.9-2.1,2.1-2.1h33.3c1.2,0,2.1,0.9,2.1,2.1   C68.8,71.7,67.8,72.7,66.7,72.7z"/>
        <path fill="#025a66" d="M62.5,81h-25c-1.2,0-2.1-0.9-2.1-2.1s0.9-2.1,2.1-2.1h25c1.2,0,2.1,0.9,2.1,2.1S63.7,81,62.5,81z"/>
      </>
  },
  timer: {
    viewBox: "0 0 30 30",
    path: <path d="M 14 3 L 14 8 A 1.0001 1.0001 0 1 0 16 8 L 16 5.2011719 C 21.038002 5.7243045 25 9.8205415 25 15 C 25 20.534534 20.534534 25 15 25 C 9.4654664 25 5 20.534534 5 15 C 5 12.546287 5.8819179 10.312748 7.3457031 8.5742188 A 1.0008008 1.0008008 0 1 0 5.8144531 7.2851562 C 4.0602383 9.3686271 3 12.065713 3 15 C 3 21.615466 8.3845336 27 15 27 C 21.615466 27 27 21.615466 27 15 C 27 8.3845336 21.615466 3 15 3 L 14 3 z M 9.3945312 9.0078125 C 9.1017031 9.0505156 8.883125 9.3939687 9.078125 9.6992188 C 10.542125 11.996219 13.041937 15.870062 13.585938 16.414062 C 14.366937 17.195062 15.633062 17.195062 16.414062 16.414062 C 17.195062 15.633063 17.195062 14.366938 16.414062 13.585938 C 15.870062 13.040937 11.996219 10.542125 9.6992188 9.078125 C 9.5974687 9.013125 9.4921406 8.9935781 9.3945312 9.0078125 z"/>
  },
  externalEditInterface: {
    viewBox: "0 0 512 512",
    path: <path d="M399.379 56.254a45 45 0 00-31.162 13.176L189.809 247.838a10 10 0 00-2.565 4.398l-23.463 84.702a10 10 0 0012.307 12.306l84.7-23.463a10 10 0 004.4-2.566l178.404-178.41a45.052 45.052 0 000-63.64L431.855 69.43a45 45 0 00-32.476-13.176zm1.023 19.996a25 25 0 0117.313 7.32l11.734 11.737a25.029 25.029 0 010 35.355l-10.605 10.606-47.09-47.09 10.605-10.608a25 25 0 0118.043-7.32zM105.23 95.16a50.057 50.057 0 00-50 50v260.592a50.057 50.057 0 0050 50h280.59a50.057 50.057 0 0050-50v-88.791a10 10 0 00-10-10 10 10 0 00-10 10v88.791a30.034 30.034 0 01-30 30h-280.59a30.034 30.034 0 01-30-30V145.16a30.034 30.034 0 0130-30h88.79a10 10 0 000-20h-88.79zm252.38 13.16l47.09 47.09-146.013 146.018-47.086-47.092L357.61 108.32zM202.191 273.213l37.623 37.623-52.039 14.416 14.416-52.04z"/>
  },
  gear: {
    viewBox: "0 0 16 16",
    path: <path d="M6.5 1c-.277 0-.5.223-.5.5v.781a5.51 5.51 0 00-1.125.469l-.555-.555a.512.512 0 00-.71 0L2.194 3.61a.512.512 0 000 .711l.563.555c-.2.355-.356.73-.469 1.125H1.5c-.277 0-.5.223-.5.5v2c0 .277.223.5.5.5h.781c.117.398.274.773.469 1.125l-.555.559a.507.507 0 000 .707L3.61 12.8a.505.505 0 00.711 0l.555-.555A5.51 5.51 0 006 12.715v.785c0 .277.223.5.5.5h2a.503.503 0 00.5-.5v-.781a5.509 5.509 0 001.125-.469l.559.55a.5.5 0 00.707 0l1.41-1.41a.5.5 0 000-.706l-.559-.559A5.52 5.52 0 0012.715 9h.785c.277 0 .5-.223.5-.5v-2c0-.277-.223-.5-.5-.5h-.79a5.48 5.48 0 00-.46-1.129l.55-.55a.505.505 0 000-.712l-1.41-1.414a.502.502 0 00-.706 0l-.555.555c-.352-.2-.73-.355-1.129-.473V1.5c0-.277-.223-.5-.5-.5zM7 2h1v.602c0 .238.168.441.398.492a4.34 4.34 0 011.579.656c.199.129.464.102.632-.066l.426-.426.707.707-.43.426a.513.513 0 00-.062.632c.313.465.527 1 .645 1.579.05.23.253.398.492.398H13v1h-.61a.498.498 0 00-.488.402 4.491 4.491 0 01-.66 1.567c-.133.199-.105.46.063.629l.437.437-.707.707-.437-.433a.496.496 0 00-.63-.063c-.476.313-1 .547-1.57.66a.502.502 0 00-.398.492V13H7v-.61a.498.498 0 00-.402-.488 4.491 4.491 0 01-1.567-.66.503.503 0 00-.633.067l-.433.433-.707-.707.433-.433a.503.503 0 00.067-.63 4.423 4.423 0 01-.664-1.574A.506.506 0 002.602 8H2V7h.61a.506.506 0 00.492-.398c.113-.57.343-1.098.656-1.57a.507.507 0 00-.063-.634l-.437-.433.707-.707.433.433c.168.168.43.196.63.067a4.423 4.423 0 011.574-.664A.506.506 0 007 2.602zm.586 3A2.514 2.514 0 005 7.414 2.514 2.514 0 007.414 10 2.514 2.514 0 0010 7.586 2.514 2.514 0 007.586 5zm-.031 1A1.494 1.494 0 019 7.555 1.494 1.494 0 017.445 9 1.494 1.494 0 016 7.445 1.494 1.494 0 017.555 6z"/>
  },
  genderNeutralUser: {
    viewBox: "0 0 16 16",
    path: <path d="M8 2C6.348 2 5 3.348 5 5s1.348 3 3 3 3-1.348 3-3-1.348-3-3-3zm0 6a5.01 5.01 0 00-5 5h1c0-2.215 1.785-4 4-4s4 1.785 4 4h1c0-2.754-2.246-5-5-5zm0-5c1.11 0 2 .89 2 2 0 1.11-.89 2-2 2-1.11 0-2-.89-2-2 0-1.11.89-2 2-2z"/>
  },
  homePage: {
    viewBox: "0 0 16 16",
    path: <path d="M8 1.32L.66 8.133l.68.734.66-.613V14h12V8.254l.66.613.68-.734L12 5.03V2h-1v2.102zm0 1.36l5 4.648V13h-3V8H6v5H3V7.328zM7 9h2v4H7z"/>
  },
  analyze100: {
    viewBox: "0 0 100 100",
    path:
      <>
        <path fill="#2cb6d1" d="M75 6.2H25c-5.8 0-10.4 4.7-10.4 10.4v62.5c0 5.8 4.7 10.4 10.4 10.4h50c5.8 0 10.4-4.7 10.4-10.4V16.7c0-5.8-4.6-10.5-10.4-10.5z"/>
        <path fill="#025a66" d="M56.5 68.1c.3-9.2 8-16.4 17.2-16.1s16.4 8 16.1 17.2-8 16.4-17.2 16.1-16.4-8-16.1-17.2zm29.2 1c.2-6.9-5.2-12.7-12.1-12.9-6.9-.2-12.7 5.2-12.9 12.1-.2 6.9 5.2 12.7 12.1 12.9 6.9.2 12.7-5.2 12.9-12.1z"/>
        <path fill="#025a66" d="M47.5 90.8c0-.5.2-1.1.6-1.5l12.9-12c.8-.8 2.1-.7 2.9.1.8.8.7 2.1-.1 2.9l-12.9 12c-.8.8-2.1.7-2.9-.1-.3-.4-.5-.9-.5-1.4zM68.8 43.8H31.2c-1.2 0-2.1-.9-2.1-2.1 0-1.2.9-2.1 2.1-2.1h37.5c1.2 0 2.1.9 2.1 2.1 0 1.1-.9 2.1-2 2.1zM52.1 58.3H31.2c-1.2 0-2.1-.9-2.1-2.1 0-1.2.9-2.1 2.1-2.1H52c1.2 0 2.1.9 2.1 2.1.1 1.2-.9 2.1-2 2.1zM68.8 29.2H31.2c-1.2 0-2.1-.9-2.1-2.1S30 25 31.2 25h37.5c1.2 0 2.1.9 2.1 2.1s-.9 2.1-2 2.1zM45.8 72.9H31.2c-1.2 0-2.1-.9-2.1-2.1s.9-2.1 2.1-2.1h14.6c1.2 0 2.1.9 2.1 2.1s-.9 2.1-2.1 2.1z"/>
      </>
  },
  certificate100: {
    viewBox: "0 0 100 100",
    path:
      <>
        <path fill="#2cb6d1" d="M83.3 14.6H16.7C11 14.6 6.3 19.3 6.3 25v50c0 5.7 4.7 10.4 10.4 10.4h66.7c5.7 0 10.4-4.7 10.4-10.4V25c0-5.7-4.7-10.4-10.5-10.4z"/>
        <path fill="#025a66" d="M77.1 33.3H22.9c-1.2 0-2.1-.9-2.1-2.1 0-1.2.9-2.1 2.1-2.1h54.2c1.2 0 2.1.9 2.1 2.1 0 1.2-1 2.1-2.1 2.1zM50 45.8H22.9c-1.2 0-2.1-.9-2.1-2.1 0-1.2.9-2.1 2.1-2.1H50c1.2 0 2.1.9 2.1 2.1 0 1.2-.9 2.1-2.1 2.1zM45.8 70.8c-.2 0-.5 0-.7-.1l-4.4-1.6c-1.5 1.3-3.1 1.7-4.2 1.7-2.6 0-4.7-1.6-5.9-2.7l-6.7 2.5c-.7.3-1.5.1-2.1-.4-.6-.5-.8-1.3-.6-2.1l1.6-6c.3-1.1 1.4-1.8 2.5-1.5 1.1.3 1.8 1.4 1.5 2.5l-.6 2.2 4.3-1.6c.8-.3 1.8 0 2.3.7.5.6 2.2 2.3 3.7 2.3.3 0 1 0 2-1.2.6-.7 1.5-1 2.3-.7l5.7 2.1c1.1.4 1.6 1.6 1.2 2.7-.2.7-1 1.2-1.9 1.2zM69.8 77.1c-7.5 0-13.5-6.1-13.5-13.5S62.3 50 69.8 50s13.5 6.1 13.5 13.5-6 13.6-13.5 13.6zm0-22.9c-5.2 0-9.4 4.2-9.4 9.4s4.2 9.4 9.4 9.4 9.4-4.2 9.4-9.4-4.2-9.4-9.4-9.4z"/>
        <path fill="#025a66" d="M73.5 89.5c-.8 0-1.6-.5-1.9-1.3l-5.2-12.1c-.4-1.1 0-2.3 1.1-2.7 1.1-.4 2.3 0 2.7 1.1l4.3 10.2 5.6-2.3-3.7-9c-.4-1.1.1-2.3 1.1-2.7 1.1-.4 2.3.1 2.7 1.1l4.5 11c.2.5.2 1.1 0 1.6s-.6.9-1.1 1.1l-9.4 3.8c-.1.2-.4.2-.7.2zM66 89.5c-.3 0-.5 0-.8-.2l-9.4-3.8c-.5-.2-.9-.6-1.1-1.1s-.2-1.1 0-1.6l4.5-11c.4-1.1 1.7-1.6 2.7-1.1 1.1.4 1.6 1.7 1.1 2.7l-3.7 9 7.5 3c1.1.4 1.6 1.6 1.1 2.7-.3 1-1 1.4-1.9 1.4z"/>
      </>
  },
  logoutRoundedLeft: {
    viewBox: "0 0 30 30",
    path: <path d="M15 3c-2.555 0-4.917.819-6.854 2.182a1 1 0 101.15 1.636A9.852 9.852 0 0115 5c5.535 0 10 4.465 10 10s-4.465 10-10 10a9.852 9.852 0 01-5.703-1.818 1 1 0 00-1.15 1.636A11.876 11.876 0 0015 27c6.615 0 12-5.385 12-12S21.615 3 15 3zM6.98 9.99a1 1 0 00-.687.303l-3.912 3.912a1 1 0 00.002 1.592l3.91 3.91a1 1 0 101.414-1.414L5.414 16H16a1 1 0 100-2H5.414l2.293-2.293A1 1 0 006.98 9.99z"/>
  },
  longArrowLeft: {
    viewBox: "0 0 30 30",
    path: <path d="M6.98 8.99a1 1 0 00-.687.303l-4.912 4.912a1 1 0 00-.004 1.588 1 1 0 00.006.004l4.91 4.91a1 1 0 101.414-1.414L4.414 16H28a1 1 0 100-2H4.414l3.293-3.293A1 1 0 006.98 8.99z"/>
  },
  menuRounded: {
    viewBox: "0 0 50 50",
    path: <path d="M5 8a2 2 0 100 4h40a2 2 0 100-4H5zm0 15a2 2 0 100 4h40a2 2 0 100-4H5zm0 15a2 2 0 100 4h40a2 2 0 100-4H5z"/>
  },
  plusMath: {
    viewBox: "0 0 50 50",
    path:
    <path d="M23 9v14H9v4h14v14h4V27h14v-4H27V9z"/>
  },
  sortDown: {
    viewBox: "0 0 24 24",
    path:
    <path d="M5.707 10.707l5.586 5.586a.999.999 0 001.414 0l5.586-5.586c.63-.63.184-1.707-.707-1.707H6.414c-.891 0-1.337 1.077-.707 1.707z"/>
  },
  testResults: {
    viewBox: "0 0 30 30",
    path:
      <path d="M15 0c-1.645 0-3 1.355-3 3H8C6.355 3 5 4.355 5 6v18c0 1.645 1.355 3 3 3h14c1.645 0 3-1.355 3-3V6c0-1.645-1.355-3-3-3h-4c0-1.645-1.355-3-3-3zm0 2c.564 0 1 .436 1 1 0 .564-.436 1-1 1-.564 0-1-.436-1-1 0-.564.436-1 1-1zM8 5h4v1a1 1 0 001 1h4a1 1 0 001-1V5h4c.565 0 1 .435 1 1v18c0 .565-.435 1-1 1H8c-.565 0-1-.435-1-1V6c0-.565.435-1 1-1zm3 6a1 1 0 100 2h4a1 1 0 100-2h-4zm8 0a1 1 0 00-1 1 1 1 0 001 1 1 1 0 001-1 1 1 0 00-1-1zm-8 4a1 1 0 100 2h4a1 1 0 100-2h-4zm8 0a1 1 0 00-1 1 1 1 0 001 1 1 1 0 001-1 1 1 0 00-1-1zm-8 4a1 1 0 100 2h4a1 1 0 100-2h-4zm8 0a1 1 0 00-1 1 1 1 0 001 1 1 1 0 001-1 1 1 0 00-1-1z"/>
  },
  viewFile: {
    viewBox: "0 0 16 16",
    path:
      <path d="M4.475 2c-.823 0-1.5.677-1.5 1.5v9c0 .823.677 1.5 1.5 1.5h3.5v-1h-3.5a.493.493 0 01-.5-.5v-9c0-.281.218-.5.5-.5h4.5v3h3v1h1V5.293L9.682 2H4.475zm5.5 1.707L11.268 5H9.975V3.707zM13 8c-1.65 0-3 1.35-3 3 0 .646.21 1.242.56 1.732l-2.539 2.54.708.707 2.539-2.54c.49.35 1.086.561 1.732.561 1.65 0 3-1.35 3-3s-1.35-3-3-3zm0 1c1.11 0 2 .89 2 2 0 1.11-.89 2-2 2-1.11 0-2-.89-2-2 0-1.11.89-2 2-2z"/>
  },
  checkmarkGreen: {
    viewBox: "0 0 480 480",
    path:
      <path fill="#6be3a2" d="M240 48C134 48 48 134 48 240s86 192 192 192 192-86 192-192c0-34.9-9.4-67.5-25.7-95.7l-180 179.9c-3 3-7.1 4.7-11.3 4.7-4.2 0-8.3-1.7-11.3-4.7L132.5 253c-6.3-6.3-6.3-16.4 0-22.6 6.3-6.3 16.4-6.3 22.6 0l59.9 59.9 172.8-172.8C352.6 75.1 299.5 48 240 48zm147.8 69.5c6.9 8.3 13 17.3 18.5 26.8l37-37c6.3-6.3 6.3-16.4 0-22.6-6.3-6.3-16.4-6.3-22.6 0l-32.9 32.8z"/>
  },
  checkmarkWhite: {
    viewBox: "0 0 480 480",
    path:
      <path fill="#FFFFFF" d="M240 48C134 48 48 134 48 240s86 192 192 192 192-86 192-192c0-34.9-9.4-67.5-25.7-95.7l-180 179.9c-3 3-7.1 4.7-11.3 4.7-4.2 0-8.3-1.7-11.3-4.7L132.5 253c-6.3-6.3-6.3-16.4 0-22.6 6.3-6.3 16.4-6.3 22.6 0l59.9 59.9 172.8-172.8C352.6 75.1 299.5 48 240 48zm147.8 69.5c6.9 8.3 13 17.3 18.5 26.8l37-37c6.3-6.3 6.3-16.4 0-22.6-6.3-6.3-16.4-6.3-22.6 0l-32.9 32.8z"/>
  },
  document100: {
    viewBox: "0 0 100 100",
    path:
      <>
        <path fill="#2cb6d1" d="M82.7 29.8L61.9 8.9c-.4-.4-.9-.6-1.5-.6H27.1c-5.7 0-10.4 4.7-10.4 10.4v62.5c0 5.7 4.7 10.4 10.4 10.4h45.8c5.7 0 10.4-4.7 10.4-10.4v-50c0-.5-.2-1-.6-1.4z"/>
        <path fill="#005565" d="M83.3 33.3H68.8c-5.7 0-10.4-4.7-10.4-10.4V8.3c0-1.2.9-2.1 2.1-2.1 1.2 0 2.1.9 2.1 2.1v14.6c0 3.4 2.8 6.2 6.3 6.2h14.6c1.2 0 2.1.9 2.1 2.1s-1.1 2.1-2.3 2.1zM66.7 52.1H33.3c-1.2 0-2.1-.9-2.1-2.1 0-1.2.9-2.1 2.1-2.1h33.3c1.2 0 2.1.9 2.1 2.1.1 1.1-.9 2.1-2 2.1zM66.7 62.5H33.3c-1.2 0-2.1-.9-2.1-2.1s.9-2.1 2.1-2.1h33.3c1.2 0 2.1.9 2.1 2.1s-.9 2.1-2 2.1zM54.2 72.9H33.3c-1.2 0-2.1-.9-2.1-2.1 0-1.1.9-2.1 2.1-2.1h20.8c1.2 0 2.1.9 2.1 2.1 0 1.2-.9 2.1-2 2.1z"/>
      </>
  },
  highPriority: {
    viewBox: "0 0 100 100",
    path:
      <>
        <path fill="#fff" d="M95.1 43.1L56.9 4.9c-3.8-3.8-10-3.8-13.8 0L4.9 43.1c-3.8 3.8-3.8 10 0 13.8L43 95c1.9 1.9 4.4 2.9 6.9 2.9s5-1 6.9-2.9l38.1-38.1c4-3.8 4-10 .2-13.8z"/>
        <path fill="#fc9c9c" d="M46.6 73.4c0-1.9 1.5-3.4 3.4-3.4s3.4 1.5 3.4 3.4-1.5 3.4-3.4 3.4-3.4-1.5-3.4-3.4zm1.1-15.5l-.3-32.3c0-1.5 1.1-2.5 2.5-2.5s2.5 1 2.5 2.5l-.2 32.3c0 1.5-.9 2.3-2.3 2.3s-2.1-.9-2.2-2.3z"/>
      </>
  },
  highPriorityFilled: {
    viewBox: "0 0 100 100",
    path:
      <>
        <path fill="#fc9c9c" d="M95.1 43.1L56.9 4.9c-3.8-3.8-10-3.8-13.8 0L4.9 43.1c-3.8 3.8-3.8 10 0 13.8L43 95c1.9 1.9 4.4 2.9 6.9 2.9s5-1 6.9-2.9l38.1-38.1c4-3.8 4-10 .2-13.8z"/>
        <path fill="#fff" d="M46.6 73.4c0-1.9 1.5-3.4 3.4-3.4s3.4 1.5 3.4 3.4-1.5 3.4-3.4 3.4-3.4-1.5-3.4-3.4zm1.1-15.5l-.3-32.3c0-1.5 1.1-2.5 2.5-2.5s2.5 1 2.5 2.5l-.2 32.3c0 1.5-.9 2.3-2.3 2.3s-2.1-.9-2.2-2.3z"/>
      </>
  },
  info100: {
    viewBox: "0 0 48 48",
    path:
      <>
        <circle cx="24" cy="24" r="20" fill="#2cb6d1"/>
        <path fill="#025a66" d="M22.6 13.7c0-.8.6-1.4 1.4-1.4.8 0 1.4.6 1.4 1.4 0 .8-.6 1.4-1.4 1.4-.8 0-1.4-.6-1.4-1.4zm.4 20.1V19.2c0-.7.4-1.1 1-1.1s1 .5 1 1.1v14.6c0 .7-.4 1.1-1 1.1s-1-.5-1-1.1z"/>
      </>
  },
  location100: {
    viewBox: "0 0 100 100",
    path:
      <>
        <path fill="#2cb6d1" d="M50 4.2c-19.5 0-35.4 15.9-35.4 35.4C14.6 58 41.9 95.8 50 95.8S85.4 58 85.4 39.6C85.4 20.1 69.5 4.2 50 4.2z"/>
        <path fill="#025a66" d="M50 56.2c-9.2 0-16.7-7.5-16.7-16.7S40.8 22.9 50 22.9s16.7 7.5 16.7 16.7S59.2 56.2 50 56.2zm0-29.1c-6.9 0-12.5 5.6-12.5 12.5S43.1 52.1 50 52.1s12.5-5.6 12.5-12.5S56.9 27.1 50 27.1z"/>
      </>
  },
  maleUser100: {
    viewBox: "0 0 100 100",
    path:
      <>
        <circle cx="50" cy="50" r="41.7" fill="#2cb6d1"/>
        <path fill="#025a66" d="M77.8 83.1c-.9 0-1.7-.6-2-1.4l-.2-.6C72 70 61.7 62.6 50 62.6S28 70 24.4 81.1l-.2.6c-.4 1.1-1.5 1.7-2.6 1.3-1.1-.4-1.7-1.5-1.3-2.6l.2-.6C24.6 67 36.5 58.4 50 58.4S75.4 67 79.6 79.8l.2.6c.4 1.1-.2 2.3-1.3 2.6-.2 0-.5.1-.7.1zM50 54.2c-7.8 0-14.1-7-14.1-15.6S42.2 22.9 50 22.9s14.1 7 14.1 15.6S57.8 54.2 50 54.2zm0-27.1c-5.5 0-10 5.1-10 11.5S44.5 50 50 50s10-5.1 10-11.5-4.5-11.4-10-11.4z"/>
      </>
  },
  overview100: {
    viewBox: "0 0 100 100",
    path:
      <>
        <path fill="#2cb6d1" d="M72.9 8.3H27.1c-5.7 0-10.4 4.7-10.4 10.4v62.5c0 5.7 4.7 10.4 10.4 10.4h45.8c5.7 0 10.4-4.7 10.4-10.4V18.8c0-5.8-4.6-10.5-10.4-10.5z"/>
        <path fill="#025a66" d="M51 77.1H32.3c-1.2 0-2.1-.9-2.1-2.1 0-1.2.9-2.1 2.1-2.1H51c1.2 0 2.1.9 2.1 2.1 0 1.1-.9 2.1-2.1 2.1zM68.8 29.2H31.2c-1.2 0-2.1-.9-2.1-2.1S30 25 31.2 25h37.5c1.2 0 2.1.9 2.1 2.1s-.9 2.1-2 2.1z"/>
        <path fill="#025a66" d="M44.8 62.5H32.3c-1.2 0-2.1-.9-2.1-2.1s.9-2.1 2.1-2.1h12.5c1.2 0 2.1.9 2.1 2.1s-1 2.1-2.1 2.1z"/>
        <path fill="#025a66" d="M55.2 47.9H32.3c-1.2 0-2.1-.9-2.1-2.1 0-1.2.9-2.1 2.1-2.1h22.9c1.2 0 2.1.9 2.1 2.1 0 1.2-.9 2.1-2.1 2.1z"/>
        <circle cx="65.6" cy="75" r="3.1" fill="#025a66"/>
        <circle cx="65.6" cy="60.4" r="3.1" fill="#025a66"/>
        <circle cx="65.6" cy="45.8" r="3.1" fill="#025a66"/>
      </>
  },
  trashIcon: {
    viewBox: "0 0 24 24",
    path: (
      <>
      <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
      <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
      </>
    )
  }
,  
  page100Copy: {
    viewBox: "0 0 100 100",
    path:
      <>
        <path fill="#2cb6d1" d="M72.9 8.3H27.1c-5.7 0-10.4 4.7-10.4 10.4v62.5c0 5.7 4.7 10.4 10.4 10.4h45.8c5.7 0 10.4-4.7 10.4-10.4V18.8c0-5.8-4.6-10.5-10.4-10.5z"/>
        <path fill="#025a66" d="M66.7 47.9H43.8c-1.2 0-2.1-.9-2.1-2.1 0-1.2.9-2.1 2.1-2.1h22.9c1.2 0 2.1.9 2.1 2.1 0 1.2-1 2.1-2.1 2.1zM68.8 29.2H31.2c-1.2 0-2.1-.9-2.1-2.1S30 25 31.2 25h37.5c1.2 0 2.1.9 2.1 2.1s-.9 2.1-2 2.1z"/>
        <path fill="#025a66" d="M66.7 62.5H43.8c-1.2 0-2.1-.9-2.1-2.1 0-1.2.9-2.1 2.1-2.1h22.9c1.2 0 2.1.9 2.1 2.1 0 1.2-1 2.1-2.1 2.1z"/>
        <path fill="#025a66" d="M66.7 77.1H43.8c-1.2 0-2.1-.9-2.1-2.1 0-1.2.9-2.1 2.1-2.1h22.9c1.2 0 2.1.9 2.1 2.1 0 1.2-1 2.1-2.1 2.1z"/>
        <circle cx="33.3" cy="45.8" r="3.1" fill="#025a66"/>
        <circle cx="33.3" cy="60.4" r="3.1" fill="#025a66"/>
        <circle cx="33.3" cy="75" r="3.1" fill="#025a66"/>
      </>
  },
  pageComplete100: {
    viewBox: "0 0 100 100",
    path:
      <>
        <path fill="#2cb6d1" d="M83.3 18.8v43.9c-.8-.1-1.5-.2-2.3-.2-7.4 0-13.7 4.3-16.8 10.5H43.8c-1.2 0-2.1.9-2.1 2.1s.9 2.1 2.1 2.1h19c-.3 1.3-.5 2.7-.5 4.1 0 3.9 1.2 7.5 3.2 10.5H27.1c-5.7 0-10.4-4.7-10.4-10.4V18.8c0-5.7 4.7-10.4 10.4-10.4h45.8c5.8-.1 10.4 4.6 10.4 10.4z"/>
        <path fill="#025a66" d="M66.7 47.9H43.8c-1.2 0-2.1-.9-2.1-2.1 0-1.2.9-2.1 2.1-2.1h22.9c1.2 0 2.1.9 2.1 2.1 0 1.2-1 2.1-2.1 2.1zM68.8 29.2H31.2c-1.2 0-2.1-.9-2.1-2.1S30 25 31.2 25h37.5c1.2 0 2.1.9 2.1 2.1s-.9 2.1-2 2.1z"/>
        <path fill="#025a66" d="M66.7 62.5H43.8c-1.2 0-2.1-.9-2.1-2.1 0-1.2.9-2.1 2.1-2.1h22.9c1.2 0 2.1.9 2.1 2.1 0 1.2-1 2.1-2.1 2.1z"/>
        <path fill="#025a66" d="M68.4 73.9c-.6 1-1 2-1.4 3.1H43.8c-1.2 0-2.1-.9-2.1-2.1s.9-2.1 2.1-2.1h22.9c.7.1 1.4.5 1.7 1.1z"/>
        <path fill="#025a66" d="M99.8 81.2c0 10.3-8.4 18.8-18.8 18.8-6.5 0-12.2-3.3-15.6-8.3-2-3-3.2-6.6-3.2-10.5 0-1.4.2-2.8.5-4.1H67c-.4 1.3-.6 2.7-.6 4.2 0 4.1 1.7 7.8 4.4 10.5 2.6 2.5 6.2 4.1 10.1 4.1 8 0 14.6-6.5 14.6-14.6 0-7.3-5.3-13.3-12.3-14.4-.8-.1-1.5-.2-2.3-.2-5.4 0-10.1 2.9-12.6 7.3-.4-.6-1-1-1.8-1h-2.4c3.1-6.2 9.5-10.5 16.8-10.5.8 0 1.5.1 2.3.2 9.4 1 16.6 9 16.6 18.5z"/>
        <path fill="#025a66" d="M89 78.2l-7.3 10.4c-.3.5-.8.8-1.4.9H80c-.2 0-.5 0-.7-.1-.2-.1-.5-.2-.6-.3l-5.2-4.2c-.9-.7-1-2-.3-2.9.7-.9 2-1 2.9-.3l3.5 2.8 3.8-5.4 2.2-3.2c.7-.9 2-1.2 2.9-.5.9.6 1.2 1.9.5 2.8z"/>
        <circle cx="33.3" cy="45.8" r="3.1" fill="#025a66"/>
        <circle cx="33.3" cy="60.4" r="3.1" fill="#025a66"/>
        <circle cx="33.3" cy="75" r="3.1" fill="#025a66"/>
      </>
  },
  university100: {
    viewBox: "0 0 100 100",
    path:
      <>
        <path fill="#2cb6d1" d="M91.9 32L56.1 6c-3.7-2.7-8.6-2.7-12.3 0L8.1 32c-1.5 1.1-2.1 3-1.5 4.7.6 1.8 2.1 2.9 4 2.9h10.3v33.3h-4.2c-1.2 0-2.1.9-2.1 2.1v6.2h-4.2c-1.2 0-2.1.9-2.1 2.1v4.2c0 1.1.9 2.1 2.1 2.1h79.2c1.2 0 2.1-.9 2.1-2.1v-4.2c0-1.1-.9-2.1-2.1-2.1h-4.2V75c0-1.2-.9-2.1-2.1-2.1h-2.1V39.6h8.2c1.8 0 3.4-1.1 4-2.9.6-1.7 0-3.6-1.5-4.7z"/>
        <path fill="#005565" d="M20.8 77.1c-1.2 0-2.1-.9-2.1-2.1V39.6c0-1.2.9-2.1 2.1-2.1h8.3c1.2 0 2.1.9 2.1 2.1s-.9 2.1-2.1 2.1h-6.2V75c0 1.1-.9 2.1-2.1 2.1zM37.5 77.1c-1.2 0-2.1-.9-2.1-2.1V39.6c0-1.2.9-2.1 2.1-2.1h8.3c1.2 0 2.1.9 2.1 2.1s-.9 2.1-2.1 2.1h-6.2V75c0 1.1-.9 2.1-2.1 2.1zM54.2 77.1c-1.2 0-2.1-.9-2.1-2.1V39.6c0-1.2.9-2.1 2.1-2.1h8.3c1.2 0 2.1.9 2.1 2.1s-.9 2.1-2.1 2.1h-6.2V75c-.1 1.1-1 2.1-2.1 2.1zM70.8 77.1c-1.2 0-2.1-.9-2.1-2.1V39.6c0-1.2.9-2.1 2.1-2.1h8.3c1.2 0 2.1.9 2.1 2.1s-.9 2.1-2.1 2.1h-6.2V75c0 1.1-.9 2.1-2.1 2.1zM50 29.2c-4 0-7.3-3.3-7.3-7.3s3.3-7.3 7.3-7.3 7.3 3.3 7.3 7.3-3.3 7.3-7.3 7.3zm0-10.4c-1.7 0-3.1 1.4-3.1 3.1 0 1.7 1.4 3.1 3.1 3.1 1.7 0 3.1-1.4 3.1-3.1 0-1.7-1.4-3.1-3.1-3.1z"/>
      </>
  }
}
