import { styled } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Icon from '../../../Icon';

enum AlertTitles {
  "ineligible" = "Not Eligible",
  "eligible" = "Eligible",
}

enum AlertMessage {
  "ineligible" = "To become eligible for document review assignments, please complete the missing items below.",
  "eligible" = "Congratulations! You are currently eligible for document review assignments.",
}

enum Colors {
  "ineligible" = "#FC9C9C",
  "eligible" = "#6BE3A2",
}

enum IconNames {
  "ineligible" = 'highPriority',
  "eligible" = "checkmarkWhite",
}

type EligibilityStatusProps = {
  status: 'eligible' | 'ineligible'
}

const EligibilityStatus = ({ status }:EligibilityStatusProps) => {

  const CustomAlert = styled(Alert)({
    borderRadius: 10,
    fontSize: 14,
    fontWeight: 300,
    padding: '10px 15px',
    border: '1px solid',
    backgroundColor: Colors[status],
    borderColor: Colors[status],
    color: '#FFFFFF',
    fontFamily: 'Open Sans',
    alignItems: 'center',
  })

  const CustomAlertTitle = styled(AlertTitle)({
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 0
  })

  return (
    <CustomAlert severity="success" icon={<Icon name={IconNames[status]} size='28' />}>
      <CustomAlertTitle>Eligibility Status: { AlertTitles[status] }</CustomAlertTitle>
      {AlertMessage[status]}
    </CustomAlert>
  )
}

export default EligibilityStatus