import classes from './Assessment.module.scss'
import CSS from 'csstype'
import PulseWheelTimer from './PulseWheelTimer'
import PulseWheelTimeUpMessage from './PulseWheelTimeUpMessage'

type PulseWheelProps = {
  show: boolean,
  style?: CSS.Properties,
  timer?: number,
  timeUp?: boolean,
  setTimeUp?: React.Dispatch<boolean>
}

const PulseWheel = (props: React.PropsWithChildren<PulseWheelProps>) => {
  
  const showOverride = () => (props.timeUp === true) || props.show
  
  const renderHolderChild = () => {
    if (showOverride() === true){
      return (
        <div className={`${classes.pulse_wheel_holder_child}`}>
          {renderMessage()}
          {registerTimer()}
          {renderPulseWheel()}
        </div>
      )
    }
    return null
  }
  
  const renderMessage = () => {
    if (props.timeUp === true && props.children){
      return <PulseWheelTimeUpMessage>{props.children}</PulseWheelTimeUpMessage>
    }
    return null
  }
  
  const registerTimer = () => {
    if (!(props.timeUp === true && props.children) && typeof props.timer === 'number'){
      return <PulseWheelTimer timeoutLength={props.timer} setTimeUp={props.setTimeUp}/>
    }
    return null
  }
  
  const renderPulseWheel = () => {
    if (!(props.timeUp === true && props.children)){
      return <div className={`${classes.pulse_wheel}`} style={props.style ?? {borderColor: '#00f'}}/>
    }
    return null
  }
  
  
  return ( <> {renderHolderChild()} </> )
}

export default PulseWheel