import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import classes from '../Home.module.scss'
import Button from '../../shared/ComponentLibrary/RButton'
import useSystemMessages from '../../../customHooks/useSystemMessages'

const SystemMessage = () => {
  const { getSystemMessages, createSmAcknowledgement } = useSystemMessages()
  const [systemMessages, setSystemMessagesData] = useState<SystemMessagesData>({})
  var HTMLContent: any;

  useEffect(() => {
    getSystemMessages(setSystemMessagesData)
  }, [])

  if(systemMessages) {
    HTMLContent = {__html: systemMessages.systemMessages?.content}
  }

  const updateProfile: any = () => {
    var acknowledgement: boolean = true
    createSmAcknowledgement(acknowledgement)
  }

  return (
    <div className={classes.sm_container}>
      <div className={classes.heading} >
        <div className={classes.title}>System Message</div>
      </div>
      <div dangerouslySetInnerHTML={HTMLContent} className={classes.rich_text_html}/>
      <div className={classes.form}>
        <div className='d-flex justify-content-center mt-4'>
          <Link to='/'>
            <Button size='large' innerText='Acknowledge' onClickMethod={updateProfile} />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default SystemMessage