import { useLazyQuery, useMutation } from '@apollo/client'
import { SM_ACKNOWLEDGEMENT } from "../graphql/mutations"
import { SYSTEM_MESSAGES } from "../graphql/queries"
import { toast } from 'react-toastify'
import { useDispatch } from "react-redux"
import { Dispatch } from "redux"
import { setUserAction } from "../redux/actions/userActions"
import { datadogLogs } from '@datadog/browser-logs';

const useSystemMessages = () => {
  const [, {refetch: fetchSystemMessages}] = useLazyQuery(SYSTEM_MESSAGES)
  const [smAcknowledgement] = useMutation(SM_ACKNOWLEDGEMENT)
  const dispatch: Dispatch<any> = useDispatch()


  const getSystemMessages = (setSystemMessagesData: React.Dispatch<React.SetStateAction<SystemMessagesData>>) => {
    datadogLogs.logger.info("Fetching system messages (if any)...");
    
    fetchSystemMessages().then((response) => {
      if (response.data){
        datadogLogs.logger.info("System messages fetched successfully.");
      } else {
        datadogLogs.logger.info("No system messages found.");
      }
      console.log(response.data)
      setSystemMessagesData(response.data)
    })
  }

  const createSmAcknowledgement = (acknowledgement: boolean) => {
    datadogLogs.logger.info("Submitting user acknowledgment of system messages...");
    
    smAcknowledgement({
      variables: {
        acknowledgement: acknowledgement
      }
    }).then((response) => {
      let user: IUser = {
        authorized: true,
        firstName: response.data.smAcknowledgement.firstName,
        lastName: response.data.smAcknowledgement.lastName,
        email: response.data.smAcknowledgement.email,
        tcAcknowledgementPending: response.data.smAcknowledgement.tcAcknowledgementPending,
        smAcknowledgementPending: response.data.smAcknowledgement.smAcknowledgementPending
      }
      dispatch(setUserAction(user))
      datadogLogs.logger.info("User acknowledgment of system messages submitted successfully.");
      toast.info("System Message has been acknowledged.")
    })
  }

  return { getSystemMessages, createSmAcknowledgement }
}

export default useSystemMessages
