export const logoutSession = () => {
  localStorage["credentials"] = JSON.stringify({
    ...JSON.parse(localStorage["credentials"]),
    authorizationToken: null,
    expiry: null,
    uuid: null
  })
}

export const checkSession = () => {
  const credentials = JSON.parse(localStorage.getItem('credentials')!)

  if (credentials?.expiry) {
    let expiry_date = new Date(credentials.expiry * 1000)
    return new Date() >= expiry_date
  }
  return false
}
