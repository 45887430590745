import React, { useState, useEffect, ChangeEvent } from 'react'
import InformationSection from "../InformationSection"
import { useForm, SubmitHandler } from 'react-hook-form'
import SelectInputField from '../../../shared/ComponentLibrary/RSelectInputField'
import InputField from '../../../shared/ComponentLibrary/RInputField'
import Button from '../../../shared/ComponentLibrary/RButton'
import classes from '../Sections.module.scss'
import useOptions from '../../../../customHooks/useOptions'
import useProfile from '../../../../customHooks/useProfile'
import {
  countries,
  getCountryCode,
  getCountryData,
  getEmojiFlag,
} from 'countries-list';


interface IDataToSubmit {
  pcPronouns?: string
  countryId?: number
  address1?: string
  address2?: string
  city?: string
  state?: string
  zipcode?: string
  regionId?: number
  otherLanguage?: string
  phoneNumber?: string
  altPhoneNumber?: string
  usCitizen?: string
  informalReferral?: string
  attorney?: boolean
  languageIds?: number[]
  primaryCountryCode?: string
  alternateCountryCode?: string
}

interface CountryOption {
  value: string;
  name: string;
}

const BasicInformation = ({profileData, setProfileData, setDirtyForm}: ProfileTabsProps) => {
  const { register, handleSubmit, watch, getValues,  setValue, formState: { isDirty, errors } } = useForm()
  const { updateUserProfileInfo } = useProfile()
  const { getCountryList, getRegionList, getLanguageList, getStateList } = useOptions()
  const [phoneNumber, setPhoneNumber] = useState<string>(profileData.phoneNumber);
  const [altPhoneNumber, setAltPhoneNumber] = useState<string>(profileData.altPhoneNumber);

  const [countryPhoneCode, setCountryPhoneCode] = useState<string | Number>(profileData.primaryCountryCode)
  const [altCountryPhoneCode, setAltCountryPhoneCode] = useState<string>(profileData.alternateCountryCode)

  const [countryCodes, setCountryCodes] = useState<CountryOption[]>([]);
  const [inValidPhoneNumber, setInValidPhoneNumber] = useState<string>('')

  
  const [error, setError] = useState('')
  const [countryListData, setCountryListData] = useState<CountryList>({})
  const [regionListData, setRegionListData] = useState<RegionList>({})
  const [languageListData, setLanguageListData] = useState<LanguageList>({})
  const [stateListData, setStateListData] = useState<StateList>({})


  useEffect(() => setDirtyForm(isDirty), [isDirty])
  useEffect(() => {
    const options: CountryOption[] = Object.values(countries).sort((a,b) => a.phone[0] - b.phone[0]).map(country => ({
      value: `${getCountryCode(country.name) as string + '+' +  country.phone[0]}`,
      name: `${getEmojiFlag(getCountryCode(country.name) as any)} +${country.phone[0] } ${country.name }`,
    }));

    setCountryCodes(options);
  }, []);



  useEffect(() => {
    if (getValues('countryId') && getValues('countryId') !== profileData?.country.value) {
      setValue('state', '')
      setValue('regionId', '')
    }
    const countryName =  countryListData?.countryList?.find((obj:any) => obj.value === getValues('countryId'))
    getStateList(selectedCountry(getValues('countryId')), setStateListData)
    getRegionList(getValues('countryId') ?? "", setRegionListData)
    let countryCode =  countryName ? getCountryCode(countryName?.name) : getCountryCode(profileData.country.name)

    if (countryCode) {
      let countryData = getCountryData(countryCode)
      if(countryData && !profileData.primaryCountryCode){
        let code = countryCode + '+' + countryData.phone[0].toString()
        setCountryPhoneCode(code)
      }
    }
  }, [watch("countryId"), profileData?.country?.value])
  
  
  useEffect(() => {
    getCountryList(setCountryListData)
    getRegionList(getValues('countryId') ?? "", setRegionListData)
    getLanguageList(setLanguageListData)
    getStateList(profileData?.country.name, setStateListData)
  }, [])

  const updateProfile: SubmitHandler<IDataToSubmit> = (data) => {
    setDirtyForm(false)
    let updatedProfileData: IUserProfileData = {
      attributes: data,
      profileSectionName: "basic info",
      setProfileData: setProfileData,
      setError: setError
    }
    updateUserProfileInfo(updatedProfileData)
  }

  const selectedCountry = (value: string): string => {
    let countries = countryListData.countryList?.filter((item) => {
      if (item.value === value ) return item 
    })
    return countries && countries?.length > 0 ? countries[0].name : ''
  }

  const handlePhoneNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    const input: string = e.target.value;
    const numericInput: string = input.replace(/\D/g, '');
    let formattedPhoneNumber: string = '';
    if ((getValues('primaryCountryCode') === `${getCountryCode('United States')}+1`  || getValues('primaryCountryCode') === `${getCountryCode('Canada')}+1`) &&
           numericInput.length > 0) {
      if (numericInput.length <= 3) {
        formattedPhoneNumber = `(${numericInput}`;
      } 
      else if (numericInput.length <= 6){
        formattedPhoneNumber = `(${numericInput.slice(0, 3)}) ${numericInput.slice(3, 6)}`;
      }
      else {
        formattedPhoneNumber = `(${numericInput.slice(0, 3)}) ${numericInput.slice(3, 6)}-${numericInput.slice(6, 10)}`;
      }
    }else{
      if(numericInput.length > 16 ){
        formattedPhoneNumber = numericInput.slice(0,16)
        setInValidPhoneNumber('Validation failed: Phone number is too long (maximum is 16 characters)')
      }else{
        formattedPhoneNumber = numericInput
        setInValidPhoneNumber('')
      }
    }
    setCountryPhoneCode(getValues('primaryCountryCode'))
    setPhoneNumber(formattedPhoneNumber);
  };

  const handleAltPhoneNumberChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const input: string = e.target.value;
    const numericInput: string = input.replace(/\D/g, '');
    let formattedPhoneNumber: string = '';
    if ((getValues('alternateCountryCode') === `${getCountryCode('United States')}+1`  || getValues('alternateCountryCode') === `${getCountryCode('Canada')}+1`) &&
           numericInput.length > 0) {
      if (numericInput.length <= 3) {
        formattedPhoneNumber = `(${numericInput}`;
      }
      else if (numericInput.length <= 6){
        formattedPhoneNumber = `(${numericInput.slice(0, 3)}) ${numericInput.slice(3, 6)}`;
      }
      else {
        formattedPhoneNumber = `(${numericInput.slice(0, 3)}) ${numericInput.slice(3, 6)}-${numericInput.slice(6, 10)}`;
      }
    }else{
      if(numericInput.length > 16 ){
        formattedPhoneNumber = numericInput.slice(0,16)
        setInValidPhoneNumber('Validation failed: Alternate Phone number is too long (maximum is 16 characters)')
      }else{
        formattedPhoneNumber = numericInput
        setInValidPhoneNumber('')
      }
    }
    setAltCountryPhoneCode(getValues('alternateCountryCode'))
    setAltPhoneNumber(formattedPhoneNumber);
  };
  
  return (
    <div className={classes.section}>
      {
        profileData && 
        <form className={classes.form} onSubmit={handleSubmit(updateProfile)} >
          <InformationSection title='Details'>
            <SelectInputField
              label='Pronouns'
              name='Pronouns'
              register={{...register('pcPronouns')}}
              size="small"
              value={profileData.pcPronouns}
              itemsList={
                [
                  {name: 'he/him', value: 'he/him'},
                  {name: 'she/her', value: 'she/her'},
                  {name: 'they/them', value: 'they/them'},
                ]
              }
            />
          </InformationSection>

          <InformationSection title='Location'>
            <div className={classes.side_by_side}>
              <div className={classes.input_field}>
                {
                  countryListData.countryList &&
                  <SelectInputField
                    label='Country'
                    value={profileData?.country?.value}
                    privacy="allow"
                    size='small'
                    name='Country'
                    register={{...register('countryId', { required: true })}}
                    itemsList={countryListData.countryList}
                    error={errors.countryId}
                    required
                  />
                }
              </div>
              <div className={classes.input_field}>
                {
                  regionListData.regionList &&
                  <SelectInputField
                    name='Base Region'
                    label='Base Region'
                    value={profileData?.region?.value}
                    privacy="allow"
                    size='small'
                    register={{...register('regionId', { required: true })}}
                    itemsList={regionListData.regionList}
                    error={errors.regionId}
                    required
                  />
                }
              </div>
            </div>
            <div className={classes.input_field}>
              <InputField
                name='Address'
                label='Address'
                defaultValue={profileData.address1}
                register={{...register('address1', { required: true })}}
                error={errors.address1}
                required
              />
            </div>
            <div className={classes.input_field}>
              <InputField
                name='address2'
                defaultValue={profileData.address2}
                register={{...register('address2')}}
                error={errors.address2}
              />
            </div>
            <div className={classes.side_by_side}>
              <div className={classes.input_field}>
                <InputField
                  name='City'
                  label='City'
                  defaultValue={profileData.city}
                  register={{...register('city', { required: true })}}
                  error={errors.city}
                  privacy='allow'
                  size='small'
                  required
                />
              </div>
                
              <div className={classes.input_field}>
                <SelectInputField
                  label='State/Province'
                  value={profileData?.state}
                  name='State'
                  privacy='allow'
                  size='small'
                  register={{...register('state', { required: true })}}
                  itemsList={stateListData?.stateList}
                  error={errors.state}
                  required
                />
              </div>
            </div>

            <div className={classes.side_by_side}>
              <div className={classes.input_field}>
                <InputField
                  name='Zipcode'
                  label='Postal Code'
                  defaultValue={profileData.zipcode}
                  register={{...register('zipcode', { required: true })}}
                  error={errors.zipcode}
                  privacy='allow'
                  size='small'
                  required
                />
              </div>
            </div>
          </InformationSection>

          <InformationSection title='Languages'>
            <div className={classes.side_by_side}>
              <div className={classes.input_field}>
                {
                  languageListData.languageList &&
                  <SelectInputField
                    label='Languages Spoken'
                    name='Languages'
                    register={{...register('languageIds')}}
                    size="small"
                    value={profileData?.languages?.map((l) => l.value)}
                    privacy="allow"
                    itemsList={languageListData.languageList}
                    multiple
                    withCheckboxes
                  />
                }
              </div>
              <div className={classes.input_field}>
                <InputField
                  name='otherLanguage'
                  label='Other Language(s)'
                  defaultValue={profileData.otherLanguage}
                  register={{...register('otherLanguage')}}
                  privacy="allow"
                  error={errors.otherLanguage}
                  size='small'
                />
              </div>
            </div>
          </InformationSection>
          {countryPhoneCode && 
            <InformationSection title='Phone Number'>
            <div className={classes.side_by_side}>
              <div className={classes.input_field}>
                <SelectInputField
                  name='Country Code'
                  label='Country Code'
                  register={{...register('primaryCountryCode', { required: true })}}
                  size='small'
                  value={countryPhoneCode}
                  itemsList = {countryCodes}
                  required
                />
              </div>
              <div className={classes.input_field}>
                <InputField
                  name='Phone Number'
                  label='Phone'
                  defaultValue={phoneNumber}
                  register={{...register('phoneNumber', { required: true })}}
                  privacy="mask"
                  error={errors.phoneNumber}
                  size='small'
                  value={phoneNumber}
                  placeholder={`${selectedCountry(getValues('countryId')) === 'United States' ? '(412) 867-5309' : ''}`}
                  required
                  onChange={handlePhoneNumberChange}
                />
              </div>
            </div>
            <div className={classes.side_by_side}>
              <div className={classes.input_field}>
              <SelectInputField
                  name='Alt Country Code'
                  label='Alt Country Code'
                  register={{...register('alternateCountryCode')}}
                  size='small'
                  value={altCountryPhoneCode?.toString()}
                  itemsList = {countryCodes}
                  withNoSelectOption = {true}
                />
                </div>
                <div className={classes.input_field}>
                <InputField
                  name='altPhoneNumber'
                  label='Alternative Phone'
                  defaultValue={profileData.altPhoneNumber}
                  register={{...register('altPhoneNumber')}}
                  privacy="mask"
                  error={errors.altPhoneNumber}
                  placeholder={`${selectedCountry(getValues('countryId')) === 'United States' ? '(412) 867-5309' : ''}`}
                  size='small'
                  onChange={handleAltPhoneNumberChange}
                  value={altPhoneNumber?.toString()}
                />
              </div>
            </div>
          </InformationSection>
          }
        

          <div className={classes.checkbox_field}>
            <input
              type="checkbox"
              defaultChecked={profileData?.usCitizen}
              data-dd-privacy="allow"
              {...register('usCitizen')}
            />
            <span>I am authorized to work in the U.S.</span>
          </div>

          { error && <div className='text-danger'>{ error }</div> }
          {inValidPhoneNumber && <div className='text-danger'>{ inValidPhoneNumber }</div> }

          <div className={classes.submit_button}>
            <Button type='submit' size='large' innerText='Update Profile' />
          </div>
        </form>
      }
    </div>
  )
}

export default BasicInformation
