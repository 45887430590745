import { useEffect, useState } from 'react';

type PulseWheelTimerProps = {
  timeoutLength: number,
  setTimeUp?: React.Dispatch<boolean>
}

const PulseWheelTimer = ({ timeoutLength, setTimeUp }:PulseWheelTimerProps) => {

  const [currentTime, setCurrentTime] = useState(0)

  useEffect(() => {
    if (typeof setTimeUp !== 'function'){
      throw new Error("PulseWheelTimer - setTimeUp must be defined")
    }
    setTimeUp(typeof currentTime === typeof timeoutLength && currentTime >= timeoutLength)
  }, [currentTime])

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime((mostRecentCurrentTime) => mostRecentCurrentTime + 1)
    }, 1000)
    return () => clearInterval(intervalId)
  }, [currentTime])

  return null
}

export default PulseWheelTimer