import { Outlet } from "react-router-dom"
import Sidebar from './Sidebar'
import classes from './Home.module.scss'
import { useLocation } from 'react-router-dom';

const SidebarLessRoutes = [
  '/assessment/instructions',
  '/assessment/practice',
  '/assessment/begin'
]

const Home = () => {
  let location = useLocation()

  const isSidebarRequired = () => !SidebarLessRoutes.includes(location.pathname)

  return (
    <div className={classes.dashboard_container}>
     {isSidebarRequired() && <Sidebar />}
      <div className={classes.content_container}>
        <Outlet />
      </div>
    </div>
  )
}

export default Home
