import * as actionTypes from "../actionTypes"

const defaultState: UserProfileStatus = {
  basicInformation: false,
  education: false,
  biography: false,
  resume: false,
  licensure: false,
  documentReviewExperience: false,
  realAssessment: false,
  profileStatus: false,
  activeTab: 'information',
  dirtyForm: false
}

const profileReducer = (state = defaultState, action: profileProgressAction) => {
  switch (action.type) {
    case actionTypes.USER_PROFILE_PROGRESS:
      return {
        ...state,
        basicInformation: action.profile.basicInformation,
        education: action.profile.education,
        biography: action.profile.biography,
        resume: action.profile.resume,
        licensure: action.profile.licensure,
        documentReviewExperience: action.profile.documentReviewExperience,
        realAssessment: action.profile.realAssessment,
        profileStatus: action.profile.profileStatus
      }
    case actionTypes.UPDATE_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.profile.activeTab
      }
    case actionTypes.RESET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: 'information'
      }
    case actionTypes.SET_DIRTY_FORM:
      return {
        ...state,
        dirtyForm: action.profile.dirtyForm,
      }
    default:
      return state
  }
}

export default profileReducer
