import { useState, useEffect } from "react"
import InformationSection from "../InformationSection"
import classes from '../Sections.module.scss'
import { useForm, SubmitHandler } from 'react-hook-form'
import Button from "../../../shared/ComponentLibrary/RButton"
import Icon from "../../../../Icon"
import useProfile from '../../../../customHooks/useProfile'
import { toast } from 'react-toastify'
import * as XLSX from 'xlsx';
import JSZip from 'jszip';
import { datadogLogs } from '@datadog/browser-logs';




const Resume = ({ profileData, setProfileData, setDirtyForm }: ProfileTabsProps) => {
  const { handleSubmit } = useForm()
  const [fileName, setFileName] = useState<string>(profileData?.resumeName || "")
  const [resumeFile, setResumeFile] = useState<any>()
  const [error, setError] = useState('')
  const [resumeDeleted, setResumeDeleted] = useState(false)
  const { updateUserProfileInfo } = useProfile()
  const [pptTraces, setpptTraces] = useState(false)


  const handleFileInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files && event.target.files[0];
    if (selectedFile &&  ['application/pdf','application/msword','application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(selectedFile.type)) {
      const fileReader = new FileReader();
      validFiles(fileReader, selectedFile)
      fileReader.readAsArrayBuffer(selectedFile);
    } else {
      invalidFormatToast()
    }
  };

  const validFiles = (fileReader: FileReader, selectedFile: File) => {
    fileReader.onload = (event) => {
      if (event.target && event.target.result) {
        const arrayBuffer = event.target.result;
        const pdfHeader = new Uint8Array(arrayBuffer.slice(0, 8) as ArrayBuffer);
        if(['application/pdf'].includes(selectedFile.type)){
          validPdf(pdfHeader, selectedFile)
        }else if (['application/msword','application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(selectedFile.type)){
          const headerBytes = new Uint8Array(arrayBuffer.slice(0, 4) as ArrayBuffer);
          checkIfPPTXConvertedDOC(arrayBuffer).then((result) => {
            if (result) {
              invalidFormatToast()
              console.log("Converted from PPTX to DOC");
            } else {
              if(checkIfXlsxConvertedDoc(arrayBuffer)){
                invalidFormatToast()
              }else{
                validWordFile(headerBytes, selectedFile, pdfHeader)
              }
            }
          });
        }
      } else {
        invalidFormatToast()
      }
    };
  }

  const validPdf = (pdfHeader:Uint8Array, selectedFile:File) => {
    const validPDFHeader = pdfHeader[0] === 0x25 && pdfHeader[1] === 0x50 && pdfHeader[2] === 0x44 && pdfHeader[3] === 0x46;
    if (validPDFHeader) 
    {
      setDirtyForm(true);
      setFileName(selectedFile.name);
      setResumeFile(selectedFile);
      setResumeDeleted(false);
    }else{
      invalidFormatToast()
    }
  }

  const validWordFile = (headerBytes: Uint8Array, selectedFile: File, pdfHeader: Uint8Array ) => {
    const validPDFHeader = pdfHeader[0] === 0x25 && pdfHeader[1] === 0x50 && pdfHeader[2] === 0x44 && pdfHeader[3] === 0x46; 
    //For pdfs converted to docx if they are not corrupted and valid they will still have pdfHeader
    const validDOCHeader =
    headerBytes[0] === 0xD0 &&
    headerBytes[1] === 0xCF &&
    headerBytes[2] === 0x11 &&
    headerBytes[3] === 0xE0;

    const validDOCXHeader =
    headerBytes[0] === 0x50 &&
    headerBytes[1] === 0x4B &&
    headerBytes[2] === 0x03 &&
    headerBytes[3] === 0x04;

    if  ((validDOCHeader || validDOCXHeader || validPDFHeader)) {
        setDirtyForm(true);
        setFileName(selectedFile.name);
        setResumeFile(selectedFile);
        setResumeDeleted(false);
      }else{
        invalidFormatToast()
      } 
  }
  
  const checkIfXlsxConvertedDoc = (arrayBuffer: any) => {
    try{
      XLSX.read(arrayBuffer, { type: 'array' });
      return true
     }catch(error){
      return false
     }
  }

  const checkIfPPTXConvertedDOC = (arrayBuffer: any) => {
    const zip = new JSZip();

     return zip.loadAsync(arrayBuffer).then((zipData) => {
      let powerPointTracesFound = false;

      for (const key in zipData.files) {
        zipData.files[key]?.async('string').then((fileContent) => {
          if (fileContent && (fileContent.includes('presentation') || fileContent.includes('slide'))) {
            console.log('Traces of PowerPoint content found');
            powerPointTracesFound = true;
          }
        });
      }

      for (const key in zipData.files) {
        if (key.includes('ppt/') || key.includes('ppt/slides')) {
          console.log('Traces of PowerPoint file structure found');
          powerPointTracesFound = true;
        }
      }
      
     return powerPointTracesFound;
    }).catch((error) => {
      console.error('Error processing the file:', error);
      return false;
    });
  };
  

  interface IDataToSubmit {
    resume?: any
    resumeDeleted?: boolean
  }

  const updateProfile: SubmitHandler<IDataToSubmit> = (data) => {
    setDirtyForm(false)
    let logDetails: Record<string,any> = {};
    if (!resumeDeleted) {
      logDetails["details"] = {
        name: resumeFile.name,
        lastModifiedDate: resumeFile.lastModifiedDate,
        size: resumeFile.size,
        "type": resumeFile.type
      };
    }
    datadogLogs.logger.info('Resume ' + (!resumeDeleted ? 'Uploaded' : 'Deleted'), logDetails);
    let updatedProfileData: IUserProfileData = {
      attributes: {resume: !resumeDeleted ? resumeFile : null, resumeDeleted: resumeDeleted},
      profileSectionName: "resume section",
      setProfileData: setProfileData,
      setError: setError
    }
    updateUserProfileInfo(updatedProfileData)
  }

  const onUploadResumeHandler = () => {
    document.getElementById('resume')?.click()
  }

  const invalidFormatToast = () => {
    toast.error("Invalid file type detected. Please upload a .pdf, .doc or .docx file only.")
    const fileInput = document.getElementById('resume') as HTMLInputElement;
    fileInput.value = ''
  }

  const handleClearFile = () => {
    setFileName('')
    setDirtyForm(true)
    const fileInput = document.getElementById('resume') as HTMLInputElement;
    fileInput.value = ''
    fileInput.value === '' && setResumeDeleted(true)
  }
  useEffect(() => setDirtyForm(false), [])

  return (
    <div className={classes.section}>
      <form className={classes.form} onSubmit={handleSubmit(updateProfile)} >
        <InformationSection title="Resume">
          <label className={classes.label}>
            Please upload a .pdf or .docx resume.
          </label>
          {
            fileName && (
              <div className={classes.resume_section}>
                <div className={classes.title}>
                  {fileName}
                  <div className={classes.icons}>
                    <button onClick={handleClearFile}>
                      <Icon name='deleteSign' size='16' color='#025A66' />
                    </button>
                  </div>
                </div>
              </div>
            )
          }
          <div>
            <input
              id="resume"
              type="file"
              accept="application/pdf,application/msword,
  application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              onChange={handleFileInputChange}
              name="resume"
              style={{ display: 'none' }}
            />
          </div>

          <div className={classes.add_button}>
            <Button type='button' size='small' onClickMethod={onUploadResumeHandler} innerText={fileName ? 'Replace' : '+ Upload Resume'} variant='outlined' />
          </div>
        </InformationSection>

        <div className={classes.submit_button}>
          <Button type='submit' size='large' innerText='Update Profile' />
        </div>
      </form>
    </div>
  )
}


export default Resume
