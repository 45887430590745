import { useLocation, Route, Routes } from "react-router-dom"
import Authentication from '../Authentication'
import ResetPassword from '../Authentication/PasswordReset'
import ChangePassword from '../Authentication/ChangePassword'
import useAuthentication from "../../customHooks/useAuthentication"
import ResendVerification from '../Authentication/ResendVerification'
import PrivateRoute from './PrivateRoute'
import {Header, Footer} from '../Layout'
import {
  Home,
  Dashboard,
  Assessment,
  AssessmentIntroduction,
  AssessmentInstructions,
  StartAssessment,
  PracticeAssessment,
  Profile,
  Account,
  Page404
} from '../Pages'
import classes from './AppRoutes.module.scss'

const PathWithLeftAlignedImage = [
  '/',
  '/account',
  '/profile',
  '/assessment',
  '/assessment/begin',
  '/assessment/instructions',
  '/assessment/practice',
]

const AppRoutes = () => {
  let location = useLocation()
  const requiresLeftAlignedBackground = () => PathWithLeftAlignedImage.includes(location.pathname)
  const { signIn } = useAuthentication()


  return(
    <div className={`${classes.page} ${requiresLeftAlignedBackground() && classes.left_aligned_bg}` }>
      <Header />
      <div className={classes.page_container}>
        <div className={classes.page_content}>
          <Routes>
            <Route path="/" element={<PrivateRoute path='/' Component={Home} />}>
              <Route index element={<Dashboard />} />
              <Route path="account" element={<Account />} />
              <Route path="profile" element={<Profile />} />
              <Route path="assessment" element={<PrivateRoute path='/assessment' Component={Assessment} />}>
                <Route index element={<AssessmentIntroduction />} />
                <Route path="instructions" element={<AssessmentInstructions />} />
                <Route path="practice" element={<PracticeAssessment />} />
                <Route path="begin" element={<StartAssessment />} />
              </Route>
            </Route>
            <Route path="signup" element={<Authentication />} />
            <Route path="signin" element={<Authentication />} />
            <Route path="reset_password" element={<ResetPassword />} />
            <Route path="resend_verification" element={<ResendVerification SignInHandler={signIn} />} />

            <Route path="confirmation/:token" element={<Authentication />} />
            <Route path="change_password/:token" element={<ChangePassword />} />
            <Route path="*" element={<Page404 />} />
          </Routes>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default AppRoutes
