import { useState } from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import classes from './RTabs.module.scss'
import { useDispatch } from "react-redux"
import { Dispatch } from "redux"
import { updateActiveTab, setDirtyForm } from '../../../../redux/actions/profileActions'
import { useSelector } from "react-redux"
import { useEffect } from 'react'
import WarningModal from '../../../Pages/Sidebar/WarningModal'


type TabsType = {
  [key: string]: { value: string, label: string }
}

const TabsData: TabsType = Object.freeze({
  'information': {value: 'information', label: 'Basic Information'},
  'education': {value: 'education', label: 'Education'},
  'about': {value: 'about', label: 'About Me'},
  'resume': {value: 'resume', label: 'Resume'},
  'licensure': {value: 'licensure', label: 'Licensure'},
  'experience': {value: 'experience', label: 'Review Experience'},
})

type RTabsProps = {
  activeTab: string
  dirtyForm: boolean
  setActiveTab: (tab: string) => void
}

const RTabs = ({activeTab, setActiveTab, dirtyForm}: RTabsProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const profileStatus: UserProfileStatus = useSelector((state: ProfileState) => state.profile)
  const [nextTab, setNextTab] = useState('')
  const [showModal, setShowModal] = useState(false)

  const handleChange = (event: React.SyntheticEvent, tab: string) => {
    if (!dirtyForm) {
      setActiveTab(tab)
      dispatch(updateActiveTab({...profileStatus, activeTab: tab}))
    }
    else {
      setNextTab(tab)
      setShowModal(true)
    }
  }

  const confirmTabChange = () => {
    setActiveTab(nextTab)
    dispatch(setDirtyForm({...profileStatus, dirtyForm: false}))
    dispatch(updateActiveTab({...profileStatus, activeTab: nextTab}))
    setShowModal(false)
  }

  useEffect(() => setActiveTab(profileStatus.activeTab), [profileStatus.activeTab])

  return (
    <div className={classes.tabs_container}>
      <WarningModal
        show={showModal}
        setShow={setShowModal}
        onConfirm={confirmTabChange}
      />
      <Tabs
        classes={{
          flexContainer: classes.tabs,
          scroller: classes.scroller,
          indicator: classes.indicator
        }}
        value={activeTab}
        onChange={handleChange}
        variant='scrollable'
        scrollButtons={false}
      >
        {Object.keys(TabsData).map((key) => (
          <Tab
            key={key}
            classes={{
              textColorPrimary: classes.tab,
              selected: classes.active_tab
            }}
            value={TabsData[key].value}
            label={TabsData[key].label}
            disableTouchRipple
          />
        ))}
      </Tabs>
    </div>
  )
}

export default RTabs