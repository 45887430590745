import SystemMessage from "./SystemMessage"
import TermsAndConditions from "./TermsAndConditions"

type SystemMessagesProps = {
  tcAcknowledgementPending?: boolean
  smAcknowledgementPending?: boolean
}

const SystemMessages = ({ tcAcknowledgementPending, smAcknowledgementPending }: SystemMessagesProps) => {

  return (
    <div>
      {
        tcAcknowledgementPending ? <TermsAndConditions /> : (smAcknowledgementPending ? <SystemMessage /> : null)
      }
    </div>
  )
}

export default SystemMessages