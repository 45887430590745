import { Modal } from 'react-bootstrap'
import Button from '../../shared/ComponentLibrary/RButton'

type WarningModalProps = {
  show: boolean
  setShow: React.Dispatch<React.SetStateAction<boolean>>
  onConfirm: () => void
}

const WarningModal = ({show, setShow, onConfirm}: WarningModalProps) => {

  return (
    <Modal show={show} onHide={() => setShow(false)} centered>
      <div className="m-5 text-center">
        <h3>Unsaved Changes</h3>
        <div className='alert alert-danger my-4'>You have unsaved changes, Are you sure you want to leave this page? </div>
        <Modal.Footer className='d-flex justify-content-evenly pt-4'>
          <Button innerText='Cancel' onClickMethod={() => setShow(false)} variant="outlined" />
          <Button innerText='Confirm' onClickMethod={onConfirm} />
        </Modal.Footer>
      </div>
    </Modal>
  )
}

export default WarningModal