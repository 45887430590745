import classes from './Assessment.module.scss'
import { useEffect, useState, useRef } from 'react';

type AssessmentImageProps = {
  src: string
  alt: string
  onComplete?: Function
}

const AssessmentImage = ({ src, alt, onComplete }:AssessmentImageProps) => {

  const [isComplete, setIsComplete] = useState(false)
  const [resetTrigger, setResetTrigger] = useState(0)
  const elementRef = useRef<HTMLImageElement>(null)
  
  const onLoadedHandler = () => setIsComplete(true)
  
  useEffect(() => {
    setResetTrigger((currentResetTrigger) => currentResetTrigger + 1)
    setIsComplete(false)
  }, [elementRef, src]);
  
  useEffect(() => {
    if ( elementRef && elementRef.current && elementRef.current.complete){
      setIsComplete(true)
    }else{
      elementRef.current?.addEventListener("load", onLoadedHandler)
      return () => elementRef.current?.removeEventListener("load", onLoadedHandler)
    }
  }, [resetTrigger]);
  
  useEffect(() => {
    if (isComplete && typeof onComplete === "function"){
      onComplete()
    }
  }, [isComplete]);

  return (
    <img ref={elementRef} src={src} alt={alt} />
  )  
}

export default AssessmentImage