import { useState, useEffect } from "react"
import InformationSection from "../InformationSection"
import classes from '../Sections.module.scss'
import ExpandableSection from "../ExpandableSection"
import InputField from "../../../shared/ComponentLibrary/RInputField"
import SelectInputField from "../../../shared/ComponentLibrary/RSelectInputField"
import { useForm, SubmitHandler, useFieldArray } from 'react-hook-form'
import Button from "../../../shared/ComponentLibrary/RButton"
import useProfile from '../../../../customHooks/useProfile'
import useOptions from '../../../../customHooks/useOptions'

type LicenseProps = {
  index: number
  license: UserLicense
  register: any
  yearListData: any
  stateListData: any
  expand: boolean
  onCloseHandler: () => void
}

interface IDataToSubmit {
  licenses: UserLicense[]
  foreignLicense: string
  attorney: string
}

type Role = "attorney" | "paralegal";

const Licenses = ({ profileData, setProfileData, setDirtyForm }: ProfileTabsProps) => {

  const licensesData: UserLicense[] = profileData.licenses
  const [selectedRole, setSelectedRole] = useState<Role>(profileData.attorney ? 'attorney' : 'paralegal')
  const { updateUserLicense } = useProfile()
  const [error, setError] = useState('')
  const { getYearList, getStateList } = useOptions()
  const [yearListData, setYearListData] = useState<YearList>({})
  const [stateListData, setStateListData] = useState<StateList>({})
  const { register, handleSubmit, reset, control, formState: { isDirty } } = useForm({
    defaultValues: {
      licenses: [...licensesData],
      attorney: profileData.attorney,
      foreignLicense: profileData.foreignLicense
    }
  })
  const { fields, append, remove } = useFieldArray({ control, name: "licenses" })
  useEffect(() => {
    reset({
      licenses: [...licensesData]
    })
  }, [licensesData])

  useEffect(() => setDirtyForm(isDirty), [isDirty])
  useEffect(() => {
    getYearList(setYearListData)
    getStateList("United States", setStateListData)
  }, [])

  const updateProfile: SubmitHandler<IDataToSubmit> = (data) => {
    setDirtyForm(false)
    let licenses = data.licenses
    licenses = licenses.map((license) => {
      delete license.verified
      return license
    })
    let updatedProfileData: ILicenseData = {
      attributes: {
        licenses: data?.attorney === "attorney" ? licenses : [],
        foreignLicense: data?.foreignLicense,
        attorney: data?.attorney === "attorney" ? true : false
      },
      setProfileData: setProfileData,
      setError: setError
    }
    updateUserLicense(updatedProfileData)
  }

  const AddLicenseHandler = () => {
    const newLicense: UserLicense = {
      id: null,
      state: "",
      yearPassed: "",
      verified: false,
      attorneyId: null
    }
    append(newLicense)
  }

  const onCloseHandler = (id: number) => remove(id)

  const handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedRole(event.target.value as Role)
  }


  return (
    <div className={classes.section}>
      <form className={classes.form} onSubmit={handleSubmit(updateProfile)} >
        <InformationSection title="Licensure">

          <div className={classes.checkbox_section}>
            <div className={classes.label}>Select status:</div>
            <div className={classes.checkboxes}>
              <label>
                <input
                  type="radio"
                  {...register("attorney")}
                  value="attorney"
                  data-dd-privacy="allow"
                  checked={selectedRole === "attorney"}
                  onChange={handleRoleChange}
                /> Attorney
              </label>
              <label>
                <input
                  type="radio"
                  {...register("attorney")}
                  value="paralegal"
                  data-dd-privacy="allow"
                  checked={selectedRole === "paralegal"}
                  onChange={handleRoleChange}
                /> Paralegal or JD
              </label>
            </div>
          </div>

          {
            selectedRole === "attorney" && (fields && fields.map((license, index) => {
              return(
                <License
                  key={index}
                  index={index}
                  register={register}
                  license={license}
                  yearListData={yearListData}
                  stateListData={stateListData}
                  expand={license.state.toString() === ""}
                  onCloseHandler={() => onCloseHandler(index)}
                />
              )
            }))
          }
          
          {
            selectedRole === "attorney" && (
              <div className={classes.add_button}>
                <Button type='button' size='small' privacy='allow' onClickMethod={AddLicenseHandler} innerText='+ Add U.S. License' variant='outlined' />
              </div>
            )
          }
        </InformationSection>

        <InformationSection title="NON-U.S. QUALIFICATIONS">
          <div className={classes.input_field}>
            <InputField
              label='Describe any non-U.S. legal qualifications below.'
              defaultValue={profileData.foreignLicense}
              name="qualifications"
              register={{...register('foreignLicense')}}
              privacy='mask'
              placeholder="One item per line"
              textArea
            />
          </div>
        </InformationSection>

        { error && <div className='text-danger'>{ error }</div> }
        <div className={classes.submit_button}>
          <Button type='submit' size='large' innerText='Update Profile' />
        </div>
      </form>
    </div>
  )
}

const License = ({index, license, register, yearListData, stateListData, expand, onCloseHandler}: LicenseProps) => {

  return (
    <ExpandableSection heading={license.state} headingPrivacy='mask' onCloseHandler={onCloseHandler} expand={expand}>
      {
        license.id && (
          <div className={classes.input_field}>
            <InputField
              name='id'
              defaultValue={license.id}
              hidden={true}
              register={{...register(`licenses[${index}][id]`)}}
            />
          </div>
        )
      }
      <div className={classes.input_field}>
        {
          stateListData.stateList && (
            <SelectInputField
              name='state'
              label='State'
              value={license.state}
              privacy='mask'
              register={{...register(`licenses[${index}][state]`)}}
              itemsList={stateListData.stateList}
            />
          )
        }
      </div>
      <div className={classes.side_by_side}>
        <div className={classes.input_field}>
          <InputField
            name='attorneyId'
            label='Attorney ID / Bar#'
            defaultValue={license.attorneyId}
            privacy='mask'
            register={{...register(`licenses[${index}][attorneyId]`)}}
            size='small'
          />
      </div>
        
      <div className={classes.input_field}>
        {
          yearListData.yearList && (
            <SelectInputField
              name='Year Passed'
              label='Year Passed'
              value={license.yearPassed}
              privacy='mask'
              size='small'
              register={{...register(`licenses[${index}][yearPassed]`)}}
              itemsList={yearListData.yearList}
            />
          )
        }
      </div>
    </div>
  </ExpandableSection>
  )
}


export default Licenses