import classes from './InformationSection.module.scss'

interface InformationSectionProps {
  children?: React.ReactNode
  title: string
}


const InformationSection = ({ children, title, ...other }: InformationSectionProps) => {
  return (
    <div className={classes.information_section}>
      <div className={classes.title}>{title}</div>
      <div className={classes.content}>
        {children}
      </div>
    </div>
  )
}

export default InformationSection