import { createStore, applyMiddleware, Store } from "redux";
import RootReducer from "./reducers/RootReducer";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
};
const persistedReducer = persistReducer(persistConfig, RootReducer);


// const Store: Store<ArticleState, ArticleAction> & {
//   dispatch: DispatchType
// } = createStore(reducer, applyMiddleware(thunk))

const store: Store & { dispatch: DispatchType } = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(thunk))
);
const Persistor = persistStore(store);

export { store, Persistor };
