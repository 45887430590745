import { useState, useEffect } from 'react'
import { useSelector } from "react-redux"
import EligibilityStatus from './EligibilityStatus'
import classes from './Dashboard.module.scss'
import PageHeading from './../../shared/PageHeading'
import Icon from '../../../Icon'
import { Link } from 'react-router-dom'
import Timeline from '@mui/lab/Timeline'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import Button from '../../shared/ComponentLibrary/RButton'
import useProfile from '../../../customHooks/useProfile'


const Dashboard = () => {
  const profileStatus: UserProfileStatus = useSelector((state: ProfileState) => state.profile)
  const [profileStatusData, setProfileStatusData] = useState<UserProfileStatus>(profileStatus)
  const { getUserProfileStatus } = useProfile()
  
  const isEligible = () => Object.values(profileStatusData).includes(false) ? 'ineligible' : 'eligible'
  
  useEffect(() => getUserProfileStatus(setProfileStatusData), [])
  
  return (
    <div>
      <div>
        <div className={classes.account_button}>
          <Link className="btn" to='/account'>
            <Icon name='gear' size='36' />
          </Link>
        </div>
        <PageHeading
          title='Dashboard'
          description='Manage your account, update your profile details including licensure, education, and experience. Head to the assessment area to show off your document review skills.'
          divider
        />
      </div>
      <EligibilityStatus status={isEligible()} />
      <div className={classes.timeline_container}>
        <Timeline>
          <TimelineItem className={classes.timeline_item}>
            <TimelineSeparator>
              <TimelineDot children={<span>1</span>} className={classes.timeline_dot}/>
              <TimelineConnector className={classes.timeline_connector}/>
            </TimelineSeparator>
            <TimelineContent className={classes.timeline_content}>
              <div className={classes.timeline_content_container}>
                <div className={classes.container_heading}>
                  <Icon name='genderNeutralUser' size='26' />
                  <span>
                    Complete Your Profile
                  </span>
                </div>

                <div className={classes.grey_text}>
                  The first step to become eligible for document review assignments is to complete your profile.
                </div>

                <div className={classes.profile_step_container}>
                  <div className={classes.step_container}>
                    <Link to='/profile' state={{ from: "information" }}>
                      <div className={classes.step}>
                        <div className={classes.step_content}>
                          <Icon name='location100' size='28' />
                          <div className={classes.step_title}>Basic Information</div>
                        </div>
                          <Icon name='externalEditInterface' size='28' />
                      </div>
                    </Link>
                    {
                      profileStatusData.basicInformation ?
                      <Icon name='checkmarkGreen' size='28' /> :
                      <Icon name='highPriorityFilled' size='28' />
                    }
                  </div>

                  <div className={classes.step_container}>
                    <Link to='/profile' state={{ from: "education" }} >
                      <div className={classes.step}>
                        <div className={classes.step_content}>
                          <Icon name='university100' size='28' />
                          <div className={classes.step_title}>Education</div>
                        </div>
                          <Icon name='externalEditInterface' size='28' />
                      </div>
                    </Link>
                    {
                      profileStatusData.education ?
                      <Icon name='checkmarkGreen' size='28' /> :
                      <Icon name='highPriorityFilled' size='28' />
                    }
                  </div>

                  <div className={classes.step_container}>
                    <Link to='/profile' state={{ from: "about" }}>
                      <div className={classes.step}>
                        <div className={classes.step_content}>
                          <Icon name='bio' size='28' />
                          <div className={classes.step_title}>About Me</div>
                        </div>
                          <Icon name='externalEditInterface' size='28' />
                      </div>
                    </Link>
                    {
                      profileStatusData.biography ?
                      <Icon name='checkmarkGreen' size='28' /> :
                      <Icon name='highPriorityFilled' size='28' />
                    }
                  </div>

                  <div className={classes.step_container}>
                    <Link to='/profile' state={{ from: "resume" }}>
                      <div className={classes.step}>
                        <div className={classes.step_content}>
                          <Icon name='document100' size='28' />
                          <div className={classes.step_title}>Resume</div>
                        </div>
                          <Icon name='externalEditInterface' size='28' />
                      </div>
                    </Link>
                    {
                      profileStatusData.resume ?
                      <Icon name='checkmarkGreen' size='28' /> :
                      <Icon name='highPriorityFilled' size='28' />
                    }
                  </div>

                  <div className={classes.step_container}>
                    <Link to='/profile' state={{ from: "licensure" }}>
                      <div className={classes.step}>
                        <div className={classes.step_content}>
                          <Icon name='certificate100' size='28' />
                          <div className={classes.step_title}>Licensure</div>
                        </div>
                          <Icon name='externalEditInterface' size='28' />
                      </div>
                    </Link>
                    {
                      profileStatusData.licensure ?
                      <Icon name='checkmarkGreen' size='28' /> :
                      <Icon name='highPriorityFilled' size='28' />
                    }
                  </div>

                  <div className={classes.step_container}>
                    <Link to='/profile' state={{ from: "experience" }}>
                      <div className={classes.step}>
                        <div className={classes.step_content}>
                          <Icon name='analyze100' size='28' />
                          <div className={classes.step_title}>
                            Document Review Experience
                          </div>
                        </div>
                          <Icon name='externalEditInterface' size='28' />
                      </div>
                    </Link>
                    {
                      profileStatusData.documentReviewExperience ?
                      <Icon name='checkmarkGreen' size='28' /> :
                      <Icon name='highPriorityFilled' size='28' />
                    }
                  </div>
                </div>
              </div>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem className={classes.timeline_item}>
            <TimelineSeparator>
              <TimelineDot children={<span>2</span>} className={classes.timeline_dot}/>
              <TimelineConnector className={classes.timeline_connector}/>
            </TimelineSeparator>
            <TimelineContent className={classes.timeline_content}>
              <div className={classes.timeline_content_container}>
                <div className={classes.container_heading}>
                  <Icon name='testResults' size='26' />
                  <span>
                    Take the Assessment
                  </span>
                </div>

                <div className={classes.grey_text}>
                  {"The final step for document review assignment eligibility is the assessment. To prepare for the test, "}
                  <Link to='/assessment/instructions'>
                    {"read the instructions"}
                  </Link>
                  {" or take the "}
                  <Link to='/assessment/practice'>
                    practice test
                  </Link>
                  .
                </div>

                <div className={classes.profile_step_container}>
                  <div className={classes.step_container}>
                    <Link to='/assessment/begin' >
                      <div className={classes.step}>
                        <div className={classes.step_content}>
                          <Icon name='page100Copy' size='28' />
                          <div className={classes.step_title}>Document Review Assessment</div>
                        </div>
                        <Button innerText='Start Test' variant='outlined' />
                      </div>
                    </Link>
                    {
                      profileStatusData.realAssessment ?
                      <Icon name='checkmarkGreen' size='28' /> :
                      <Icon name='highPriorityFilled' size='28' />
                    }
                  </div>
                </div>

              </div>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem className={classes.timeline_item}>
            <TimelineSeparator>
              <TimelineDot children={<span>3</span>} className={classes.timeline_dot}/>
            </TimelineSeparator>
            <TimelineContent className={classes.timeline_content}>
              <div className={classes.timeline_content_container}>
                <div className={classes.container_heading}>
                  <Icon name='viewFile' size='26' />
                  <span>
                    Get Invited to Work on a Case
                  </span>
                </div>

                <div className={classes.grey_text}>
                  Once you've completed your profile and taken the ReviewRight assessment, we'll reach out to you with opportunities that match your background, skills, and qualifications. We are actively staffing numerous projects around the world, so you should be hearing from us soon. Remember to update your profile with all new projects (including those for other review providers), as the more information you enter, the more opportunities will come your way.
                </div>
              </div>
            </TimelineContent>
          </TimelineItem>

        </Timeline>
      </div> 
    </div>
  )
}

export default Dashboard
