import AppRoutes from "./components/AppRoutes"
import Toaster from "./components/shared/Toaster"
import { BrowserRouter } from "react-router-dom"

const App = () => {

  return (
    <div>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
      <Toaster />
    </div>
  )
}

export default App
