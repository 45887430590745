import React from "react"

interface IHtmlSafeElementConfig {
  element: keyof JSX.IntrinsicElements
  content: string | undefined
}

export const HtmlSafeElement = ({element="div", content}:IHtmlSafeElementConfig) => {
  const Wrapper = element

  return (
    <React.Fragment>
      { content && <Wrapper dangerouslySetInnerHTML={{ __html: content }} /> }
    </React.Fragment>
  )
}

