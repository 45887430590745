import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';

enum ButtonWidth {
  "small" = "130px",
  "large" = "180px",
}

enum ButtonHeight {
  "small" = "35px",
  "large" = "50px",
}

enum ButtonBorderRadius {
  "small" = "25px",
  "large" = "41px",
}

enum ButtonFontWeight {
  "small" = "300",
  "large" = "600",
}

type RButtonProps = {
  innerText?: string
  type?: "button" | "submit" | "reset" | undefined
  size?: 'small' | 'large'
  variant?: "text" | "contained" | "outlined" | undefined
  children?: React.ReactNode
  onClickMethod?: (event: React.MouseEvent<HTMLButtonElement>) => void
  hidden?: boolean
  disabled?: boolean
  privacy?: 'allow' | 'mask' | 'hidden' | 'mask-user-input'
}

const RButton = ({innerText, type, size="small", variant="contained", onClickMethod = () => false, children, hidden, disabled, privacy="allow"}: RButtonProps) => {
  
  let CustomButton = styled(Button)<ButtonProps>({
    boxShadow: 'none',
    textTransform: 'none',
    borderRadius: ButtonBorderRadius[size],
    minWidth: ButtonWidth[size],
    height: ButtonHeight[size],
    fontSize: 14,
    fontWeight: ButtonFontWeight[size],
    display: 'flex',
    gap: '7px',
    alignItems: 'center',
    padding: '6px 30px',
    border: '1px solid',
    lineHeight: 1.5,
    backgroundColor: '#2CB6D1',
    borderColor: '#2CB6D1',
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: '#2CB6D1',
      borderColor: '#2CB6D1',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#2CB6D190',
      borderColor: '#2CB6D190',
    }
  })

  if (variant === 'outlined') {
    CustomButton = styled(CustomButton)<ButtonProps>({
      backgroundColor: 'unset',
      color: '#2CB6D1',
      '&:hover': {
        color: '#FFF'
      }
    })
  }

  if (variant === 'text') {
    CustomButton = styled(CustomButton)<ButtonProps>({
      backgroundColor: 'unset',
      border:'unset',
      color: '#2CB6D1',
      fontWeight: '600',
      '&:hover': {
        backgroundColor: 'unset',
        color: '#2CB6D1'
      }
    })
  }


  return(
    <CustomButton variant={variant} size={size} type={type} onClick={onClickMethod} hidden={hidden} disabled={disabled}
		className= {`${privacy === undefined ? '' : ('dd-privacy-' + privacy)}`}
	>
      {children ? children : innerText}
    </CustomButton>
  )
}


export default RButton