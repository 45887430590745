import PageHeading from '../../shared/PageHeading'
import classes from './Assessment.module.scss'
import Button from '../../shared/ComponentLibrary/RButton'
import Icon from '../../../Icon'
import BackToHomeButton from '../../shared/BackToHomeButton'
import useAssessment from '../../../customHooks/useAssessment'
import { useForm, SubmitHandler } from 'react-hook-form'
import InputField from '../../shared/ComponentLibrary/RInputField'


const AssessmentFeedback = () => {
  const { register, handleSubmit, watch, formState: { errors } } = useForm()
  const { submitComment } = useAssessment()

  interface IDataToSubmit {
    comment: string
  }

  const submitCommentFeedback: SubmitHandler<IDataToSubmit> = (data) => {
    submitComment(data.comment)
  }

  return (
    <div className='h-100'>
      <div className={classes.header}>
        <PageHeading title='ReviewRight® Assessment Results' icon='testResults' />
        <BackToHomeButton />
      </div>

      <div className={classes.assessment_section}>
        <form onSubmit={handleSubmit(submitCommentFeedback)} >
          <div className={classes.feedback}>
            <div className={classes.congratulations}>
              Congratulations! You made it through the ReviewRight e-Discovery Assessment!
            </div>
            <div className={classes.feedback_text}>
              If you had any issues with the test, such as distractions or technical difficulties: or have general comments on the test or testing process: we'd love to hear them.
            </div>
            <div className={classes.input_field}>
              <InputField
                name="comment"
                textArea
                register={{...register('comment')}}
              />
            </div>
            <div>
              <Button type='submit' innerText='Submit Comment' size='large'/>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AssessmentFeedback