import React, { useEffect } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import classes from './Authentication.module.scss'
import Button from '../shared/ComponentLibrary/RButton'
import InputField from '../shared/ComponentLibrary/RInputField'
import useAuthentication from '../../customHooks/useAuthentication'


const ChangePassword = () => {
  const navigate = useNavigate()
  const routeParams = useParams()
  const { changePassword } = useAuthentication()
  const user: IUser = useSelector((state: UserState) => state.user)
  const { register, handleSubmit, watch, formState: { errors } } = useForm()

  const submitLogin: SubmitHandler<IFormInputs> = (data) => {
    let resetPasswordData: IResetPasswordData = { 
      ...data,
      confirmationToken: routeParams.token
    }

    console.log(resetPasswordData)
    changePassword(resetPasswordData)
  }

  useEffect(() => { (user.authorized || !routeParams.token) && navigate('/') }, [user.authorized])

  return (
    <div className={classes.authentication_container}>
      <div className={classes.heading} >
        <div className={classes.title}>Change Password</div>
        <div className={classes.content}>Fill out the form below to change your password.</div>
      </div>
      
      <form className={classes.form} onSubmit={handleSubmit(submitLogin)} >
      <div className={classes.field}>
          <InputField
            type='password'
            name='password'
            label='New Password'
            register={{...register('password', {
              required: true,
              minLength: 7
            })}}
            errorMessage="Set a minimum of 7 characters."
            error={errors.password}
            required
          />
        </div>
        <div className={classes.field}>
          <InputField
            type='password'
            name='passwordConfirmation'
            label='Confirm New Password'
            errorMessage="Doesn't match with the password."
            register={{...register('passwordConfirmation', { required: true, minLength: 7, validate: (value) => { return value === watch('password') } })}}
            error={errors.passwordConfirmation}
            required
          />
        </div>

        <div className={classes.submit_button}>
          <Button type='submit' size='large' innerText='Update Password' />
        </div>
      </form>
    </div>
  )
}

export default ChangePassword