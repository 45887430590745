import React, { useState } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { Link } from 'react-router-dom'
import classes from './Authentication.module.scss'
import Button from '../shared/ComponentLibrary/RButton'
import InputField from '../shared/ComponentLibrary/RInputField'

interface IFormInputs {
  email: string
  password: string
}

const SignIn = ({ SignInHandler }: SignInProps) => {
  const { register, handleSubmit, formState: { errors } } = useForm()
  const [error, setError] = useState('')

  const submitLogin: SubmitHandler<IFormInputs> = (data) => {
    let signInAttributes = {...data}
    signInAttributes.password = btoa(signInAttributes.password)

    let signInData: ISignInData = { 
      attributes: signInAttributes,
      setError: setError
    }

    SignInHandler(signInData)
  }

  return (
    <div className={classes.authentication_container}>
      <div className={classes.heading} >
        <div className={classes.title}>Sign In</div>
        <div className={classes.content}>Fill out the form below to sign in to your ReviewRight® account.</div>
      </div>
      
      <form className={classes.form} onSubmit={handleSubmit(submitLogin)} >
        <div className={classes.field}>
          <InputField
            name='email'
            label='Email'
            register={{...register('email', {
              required: true,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "invalid email address"
              }
            })}}
            error={errors.email}
            required
          />
        </div>
        <div className={classes.field}>
          <InputField
            type='password'
            name='password'
            label='Password'
            register={{...register('password', { required: true })}}
            error={errors.password}
            required
          />
        </div>
        { error && <div className='text-danger'>{ error }</div> }

        <div className={classes.reset_password_button}>
          <Link className="btn" to="/reset_password">Forgot Password?</Link>
        </div>
        <div className={classes.reset_password_button}>
          <Link className="btn" to="/resend_verification">Resend verification email?</Link>
        </div>

        <div className={classes.submit_button}>
          <Button type='submit' size='large' innerText='Sign In' />
        </div>
      </form>
    </div>
  )
}

export default SignIn
