import * as actionTypes from "../actionTypes"

const defaultState = {
  firstName: "",
  lastName: "",
  email: "",
  authorized: false,
  tcAcknowledgementPending: false,
  smAcknowledgementPending: false
}

const userReducer = (state = defaultState, action: UserAction) => {
  switch (action.type) {
    case actionTypes.SET_USER:
      return {
        authorized: true,
        email: action.user.email,
        firstName: action.user.firstName,
        lastName: action.user.lastName,
        tcAcknowledgementPending: action.user.tcAcknowledgementPending,
        smAcknowledgementPending: action.user.smAcknowledgementPending
      }
    case actionTypes.LOGOUT_USER:
      return {
        firstName: "",
        lastName: "",
        email: "",
        authorized: false,
        tcAcknowledgementPending: false,
        smAcknowledgementPending: false
      }
    default:
      return state
  }
}

export default userReducer
