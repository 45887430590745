import { useState, useEffect } from "react"
import InformationSection from "../InformationSection"
import classes from '../Sections.module.scss'
import { useForm, SubmitHandler } from 'react-hook-form'
import Button from "../../../shared/ComponentLibrary/RButton"
import useProfile from '../../../../customHooks/useProfile'
import InputField from "../../../shared/ComponentLibrary/RInputField"


const AboutMe = ({ profileData, setProfileData, setDirtyForm }: ProfileTabsProps) => {
  const { register, handleSubmit, watch, formState: { isDirty } } = useForm({
    defaultValues: {
      biography: profileData.biography
    }
  })
  const [error, setError] = useState('')
  const { updateUserProfileInfo } = useProfile()

  interface IDataToSubmit {
    biography?: string
  }

  const updateProfile: SubmitHandler<IDataToSubmit> = (data) => {
    setDirtyForm(false)
    let updatedProfileData: IUserProfileData = {
      attributes: {biography: data.biography},
      profileSectionName: "'about me' section",
      setProfileData: setProfileData,
      setError: setError
    }
    updateUserProfileInfo(updatedProfileData)
  }

  useEffect(() => setDirtyForm(isDirty), [isDirty])

  return (
    <div className={classes.section}>
      <form className={classes.form} onSubmit={handleSubmit(updateProfile)} >
        <InformationSection title="About Me">
          <div className={`${classes.input_field} ${classes.input_field_about}`}>
            <InputField
              label="Please highlight your qualifications, skills, background, and experience in a way that stands out. Tell us and our clients what makes you unique, special. Here are some things to focus on: How many years of experience you have; what work you have done over that time, including roles and responsibilities; your legal specialty or where you have the most expertise; any special skills; achievements; certifications and professional career goals."
              defaultValue={profileData.biography}
              name="certifications"
              placeholder="About me"
              register={{...register('biography')}}
              maxLength={1000}
              textArea
              privacy="mask"
            />
          </div>
          <div className={`${classes.required_characters} ${watch('biography').length === 1000 && classes.characters_exceeded}`}>
            { watch('biography').length } / 1000
          </div>
        </InformationSection>

        <div className={classes.submit_button}>
          <Button type='submit' size='large' innerText='Update Profile' />
        </div>
      </form>
    </div>
  )
}

export default AboutMe