import InformationSection from "../InformationSection"
import classes from '../Sections.module.scss'
import ExpandableSection from "../ExpandableSection"
import InputField from "../../../shared/ComponentLibrary/RInputField"
import SelectInputField from "../../../shared/ComponentLibrary/RSelectInputField"
import { useForm, SubmitHandler, useFieldArray } from 'react-hook-form'
import Button from "../../../shared/ComponentLibrary/RButton"
import { useState, useEffect } from "react"
import useProfile from '../../../../customHooks/useProfile'
import useOptions from '../../../../customHooks/useOptions'

type DegreeProps = {
  index: number
  degree: UserDegree
  register: any
  yearListData: any
  expand: boolean
  onCloseHandler: () => void
}

interface IDataToSubmit {
  degrees: UserDegree[]
  training: string
}


const Degrees = ({ profileData, setProfileData, setDirtyForm }: ProfileTabsProps) => {

  const degreesData: UserDegree[] = profileData.degrees
  const [error, setError] = useState('')
  const [yearListData, setYearListData] = useState<YearList>({})
  const { updateUserDegree } = useProfile()
  const { getYearList } = useOptions()

  const { register, handleSubmit, reset, control, formState: { isDirty } } = useForm({
    defaultValues: {
      degrees: [...degreesData],
      training: profileData.training
    }
  })
  const { fields, append, remove } = useFieldArray({ control, name: "degrees" })
  useEffect(() => { reset({degrees: [...degreesData]}) }, [degreesData])

  useEffect(() => setDirtyForm(isDirty), [isDirty])
  useEffect(() => getYearList(setYearListData), [])

  const updateProfile: SubmitHandler<IDataToSubmit> = (data) => {
    setDirtyForm(false)
    let updatedProfileData: IDegreeData = {
      attributes: {
        degrees: data?.degrees,
        training: data?.training
      },
      setProfileData: setProfileData,
      setError: setError
    }

    updateUserDegree(updatedProfileData)
  }

  const addDegreeHandler = () => {
    const newDegree: UserDegree = {
      degreeName: "",
      university: "",
      major: "",
      honors: "",
      dateCompleted: "",
      id: null
    }
    append(newDegree)
  }

  const onCloseHandler = (id: number) => remove(id)

  return (
    <div className={classes.section}>
      <form className={classes.form} onSubmit={handleSubmit(updateProfile)} id="form" >
        <InformationSection title="Education">
          {
            fields && fields.map((degree, index) => {
              return(
                <Degree
                  key={index}
                  index={index}
                  register={register}
                  degree={degree}
                  yearListData={yearListData}
                  expand={degree.degreeName?.toString() === ""}
                  onCloseHandler={() => onCloseHandler(index)}
                />
              )
            })
          }

          <div className={classes.add_button}>
            <Button type='button' size='small' onClickMethod={addDegreeHandler} innerText='+ Add Degree' variant='outlined' />
          </div>
        </InformationSection>
        <InformationSection title="CERTIFICATIONS / SPECIALIZED TRAINING">
          <div className={classes.input_field}>
            <InputField
              label="Certifications or Other Specialized Trainings"
              defaultValue={profileData.training}
              name="certifications"
              placeholder="One item per line"
              register={{...register('training')}}
              textArea
            />
          </div>
        </InformationSection>
        { error && <div className='text-danger'>{ error }</div> }
        <div className={classes.submit_button}>
          <Button type='submit' size='large' innerText='Update Profile' />
        </div>
      </form>
    </div>
  )
}

const Degree = ({index, degree, register, yearListData, expand, onCloseHandler}: DegreeProps) => {


  return (
    <ExpandableSection heading={degree.university} onCloseHandler={onCloseHandler} expand={expand}>
      {
        degree.id && (
          <div className={classes.input_field}>
            <InputField
              name='id'
              defaultValue={degree.id}
              hidden={true}
              register={{...register(`degrees[${index}][id]`)}}
            />
          </div>
        )
      }
      <div className={classes.input_field}>
        <InputField
          name='university'
          label="University"
          defaultValue={degree.university}
          register={{...register(`degrees[${index}][university]`)}}
        />
      </div>
      <div className={classes.side_by_side}>
        <div className={classes.input_field}>
          <InputField
            name='degreeName'
            label='Degree Name'
            defaultValue={degree.degreeName}
            register={{...register(`degrees[${index}][degreeName]`)}}
            size='small'
          />
        </div>

        <div className={classes.input_field}>
          {
            <SelectInputField
              name='Year Completed'
              label='Year Completed'
              value={degree.dateCompleted}
              privacy="mask"
              size='small'
              register={{...register(`degrees[${index}][dateCompleted]`)}}
              itemsList={yearListData?.yearList || []}
            />
          }
        </div>
      </div>
      <div className={classes.side_by_side}>
        <div className={classes.input_field}>
          <InputField
            name='major'
            label='Major'
            defaultValue={degree.major}
            register={{...register(`degrees[${index}][major]`)}}
            size='small'
          />
        </div>
          
        <div className={classes.input_field}>
          <InputField
            name='honors'
            label='Honors'
            defaultValue={degree.honors}
            register={{...register(`degrees[${index}][honors]`)}}
            size='small'
          />
        </div>
      </div>
    </ExpandableSection>
  )
}

export default Degrees