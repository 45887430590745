import React, { useState } from 'react'
import PageHeading from '../../shared/PageHeading'
import classes from './Account.module.scss'
import { useSelector } from "react-redux"
import { useForm, SubmitHandler } from 'react-hook-form'
import InputField from '../../shared/ComponentLibrary/RInputField'
import Button from '../../shared/ComponentLibrary/RButton'
import BackToHomeButton from '../../shared/BackToHomeButton'
import useProfile from '../../../customHooks/useProfile'

const Account = () => {
  const user: IUser = useSelector((state: UserState) => state.user)
  const { register, handleSubmit, watch, formState: { errors } } = useForm()
  const [error, setError] = useState('')
  const { accountInfo } = useProfile()
  interface IDataToSubmit {
    firstName: string
    lastName: string
    email: string
    password?: string
    passwordConfirmation?: string
  }

  const submitUpdatedAccountInfo: SubmitHandler<IDataToSubmit> = (data) => {
    if (data.password === "") {
      data = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email
      }
    }
    let accountData: IUserData = {
      attributes: data,
      setError: setError
    }

    accountInfo(accountData)
  }

  return (
    <div>
      <BackToHomeButton />
      <PageHeading
        icon='gear'
        title='Your Account Details'
        description='Update your name, email address or password.'
        divider
      />
      <form className={classes.form} onSubmit={handleSubmit(submitUpdatedAccountInfo)} >
        <div className={classes.side_by_side}>
          <div className={classes.field}>
            <InputField
              name='firstName'
              label='First Name'
              defaultValue={user.firstName}
              register={{...register('firstName')}}
              error={errors.firstName}
              size='small'
            />
          </div>
            
          <div className={classes.field}>
            <InputField
              name='lastName'
              label='Last Name'
              defaultValue={user.lastName}
              register={{...register('lastName')}}
              error={errors.lastName}
              size='small'
            />
          </div>
        </div>

        <div className={classes.field}>
          <InputField
            name='email'
            label='Email'
            defaultValue={user.email}
            register={{...register('email', {
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "invalid email address"
              }
            })}}
            error={errors.email}
          />
        </div>
        <div className={classes.field}>
          <InputField
            type='password'
            name='password'
            instruction='leave blank to leave unchanged'
            label='Password'
            errorMessage="Password must be of atleast 7 characters."
            register={{...register('password', { required: false, minLength: 7 })}}
            error={errors.password}
          />
        </div>
        <div className={classes.field}>
          <InputField
            type='password'
            name='passwordConfirmation'
            label='Confirm Password'
            errorMessage="Doesn't match with the password."
            register={{...register('passwordConfirmation', { required: false, minLength: 7, validate: (value) => { return value === watch('password') } })}}
            error={errors.passwordConfirmation}
          />
        </div>
        { error && <div className='text-danger'>{ error }</div> }
        <div className={classes.submit_button}>
          <Button type='submit' size='large' innerText='Update Account Details' />
        </div>
      </form>
    </div>
  )
}

export default Account