import React, { useState } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import classes from './Authentication.module.scss'
import Button from '../shared/ComponentLibrary/RButton'
import InputField from '../shared/ComponentLibrary/RInputField'
import TermsAndConditionsModal from './TermsAndConditionsModal'

const SignUp = ({ SignUpHandler }: SignUpProps) => {
  const { register, handleSubmit, watch, formState: { errors } } = useForm()
  const [error, setError] = useState('')
  const [showTermsAndConditionsModal, setShowTermsAndConditionsModal] = useState<boolean>(false)
  const [acknowledgement, setAcknowledgment] = useState(false)
  const acknowledgmentCheckBox = watch('acknowledgement');

  const submitSignUp: SubmitHandler<IFormInputs> = (data) => {
    let signInData: ISignupUserData = {
      attributes: data,
      setError: setError
    }

    SignUpHandler(signInData)
  }

  const handleShowModal = () => {
    setShowTermsAndConditionsModal(true);
  };

  return (
    <div className={classes.authentication_container}>
      <TermsAndConditionsModal
        show={showTermsAndConditionsModal}
        setShow={setShowTermsAndConditionsModal}
      />
      <div className={classes.heading} >
        <div className={classes.title}>Get Started</div>
        <div className={classes.content}>Fill out the form below to register for your ReviewRight® account.</div>
      </div>
      <form className={classes.form} onSubmit={handleSubmit(submitSignUp)} >
        <div className={classes.side_by_side}>
          <div className={classes.field}>
            <InputField
              name='first name'
              label='First Name'
              register={{...register('firstName', { required: true })}}
              error={errors.firstName}
              size='small'
              required
            />
          </div>
            
          <div className={classes.field}>
            <InputField
              name='last name'
              label='Last Name'
              register={{...register('lastName', { required: true })}}
              error={errors.lastName}
              size='small'
              required
            />
          </div>
        </div>

        <div className={classes.field}>
          <InputField
            name='email'
            label='Email'
            register={{...register('email', {
              required: true,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "invalid email address"
              }
            })}}
            error={errors.email}
            required
          />
        </div>
        <div className={classes.field}>
          <InputField
            type='password'
            name='password'
            label='Password'
            register={{...register('password', {
              required: true,
              minLength: 7
            })}}
            errorMessage="Set a minimum of 7 characters."
            error={errors.password}
            required
          />
        </div>
        <div className={classes.field}>
          <InputField
            type='password'
            name='passwordConfirmation'
            label='Confirm Password'
            errorMessage="Doesn't match with the password."
            register={{...register('passwordConfirmation', { required: true, minLength: 7, validate: (value) => { return value === watch('password') } })}}
            error={errors.passwordConfirmation}
            required
          />
        </div>
        <div className={classes.checkbox_field}>
            <input
              type="checkbox"
              defaultChecked={acknowledgement}
			  data-dd-privacy="allow"
              {...register('acknowledgement')}
            />
            <span className={classes.TCcontainer}>I have read the <span onClick={handleShowModal} className={classes.TCstatement}>terms and conditions</span>.</span>
        </div>
        { error && <div className='text-danger'>{ error }</div> }
        <div className={classes.submit_button}>
          <Button type='submit' size='large' innerText='Sign me up!' disabled={!acknowledgmentCheckBox}/>
        </div>
      </form>
    </div>
  )
}

export default SignUp
