import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import Button from '../shared/ComponentLibrary/RButton'
import useTermsAndConditions from '../../customHooks/useTermsAndConditions'
import classes from './Authentication.module.scss'

type TermsAndConditionsModalProps = {
  show: boolean
  setShow: React.Dispatch<React.SetStateAction<boolean>>
}

const TermsAndConditionsModal = ({show, setShow}: TermsAndConditionsModalProps) => {
  const { getTermsAndConditions } = useTermsAndConditions()
  const [termsAndConditions, setTermsAndConditionsData] = useState<TermsAndConditionsData>({})
  var HTMLContent: any;

  useEffect(() => {
    getTermsAndConditions(setTermsAndConditionsData)
  }, [])
  if(termsAndConditions) {
    HTMLContent = {__html: termsAndConditions.termsAndConditions?.content}
  }

  return (
    <Modal dialogClassName={classes.terms_and_conditions_modal} show={show} onHide={() => setShow(false)} centered>
      <Modal.Header closeButton>
        <Modal.Title>TERMS AND CONDITIONS</Modal.Title>
      </Modal.Header>
      <Modal.Body className={classes.tc_modal_body}>
        <div className={classes.tc_modal_container_content} dangerouslySetInnerHTML={HTMLContent} />
      </Modal.Body>
    </Modal>
  )
}

export default TermsAndConditionsModal