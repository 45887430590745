import { Link } from 'react-router-dom'
import Icon from '../../../Icon'
import classes from './BackToHomeButton.module.scss'


const BackToHomeButton = () => {
  return (
    <Link className={`btn ${ classes.button }`} to='/'>
      <Icon name='longArrowLeft' color='#2CB6D1' />
      <span className={classes.button_text}>Back to Dashboard</span>
    </Link>
  )
}

export default BackToHomeButton