import React from 'react'
import ReactDOM from 'react-dom/client'
import 'bootstrap/scss/bootstrap.scss'
import "bootstrap/dist/js/bootstrap.bundle.min";
import './index.scss'
import App from './App'
import { ApolloClient, ApolloLink, InMemoryCache, ApolloProvider, concat } from '@apollo/client'
import { ApolloLink as ApolloLinkClient} from '@apollo/client/link/core/ApolloLink'
import { Provider } from "react-redux"

import { Persistor, store } from "./redux/store"
import { PersistGate } from "redux-persist/lib/integration/react"
import { createUploadLink } from 'apollo-upload-client'
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs'


const uploadLink: ApolloLinkClient | any = createUploadLink({
  uri: process.env.REACT_APP_API_URL,
  credentials: 'include',
})

const authMiddleware: ApolloLink = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  const credentials = JSON.parse(localStorage.getItem('credentials')!)
  
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: credentials?.authorizationToken || null,
      client: credentials?.client || null,
    }
  }));

  return forward(operation);
})

const client = new ApolloClient({
  link: concat(authMiddleware, uploadLink),
  cache: new InMemoryCache({
    addTypename: false,
  })
})

datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APPID?.trim() as string,
    clientToken: process.env.REACT_APP_DATADOG_CLIENTTOKEN?.trim() as string,
    site: process.env.REACT_APP_DATADOG_HOSTNAME?.trim(),
    service: process.env.REACT_APP_DATADOG_SERVICENAME?.trim(),
    env: process.env.REACT_APP_DATADOG_ENVIRONMENT?.trim(),
    // Specify a version number to identify the deployed version of your application in Datadog 
    version: process.env.REACT_APP_VERSION_NO?.trim(), 
    sessionSampleRate:100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel:'mask-user-input'
});

datadogLogs.init({
    clientToken: process.env.REACT_APP_DATADOG_CLIENTTOKEN?.trim() as string,
    site: process.env.REACT_APP_DATADOG_HOSTNAME?.trim(),
    service: process.env.REACT_APP_DATADOG_SERVICENAME?.trim(),
    env: process.env.REACT_APP_DATADOG_ENVIRONMENT?.trim(),
    version: process.env.REACT_APP_VERSION_NO?.trim(), 
    forwardErrorsToLogs: true,
    sessionSampleRate: 100
});
    
datadogRum.startSessionReplayRecording();

const root = ReactDOM.createRoot(document.getElementById('root')!)

root.render(
  <Provider store={store}>
    <PersistGate persistor={Persistor}>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </PersistGate>
  </Provider>
)
