type PulseWheelTimeUpMessageProps = {
  
}

const PulseWheelTimeUpMessage = (props:React.PropsWithChildren<PulseWheelTimeUpMessageProps>) => {

  return (
    <>{props.children}</>
  )  
}

export default PulseWheelTimeUpMessage