import classes from './Assessment.module.scss'

type QuestionFeedbackProps = {
  answer: string
  correct: boolean | null
  statement: string | null
}

const QuestionFeedback = ({ answer, correct, statement }:QuestionFeedbackProps) => {
  return (
    <div className={classes.question_feedback}>
      <div className={classes.answer}>Your Answer: {answer}</div>
      <div className={`${classes.statement} ${correct === true ? classes.correct : classes.incorrect}`}>
        <span className={classes.result}>{correct === null && "Unanswered"}</span>
        {statement}
      </div>
    </div>
    )
}

export default QuestionFeedback