import * as actionTypes from "../actionTypes"


export const profileProgressAction = (profileProgress: UserProfileStatus) => (dispatch: DispatchType) => {
  const action: profileProgressAction = {
    type: actionTypes.USER_PROFILE_PROGRESS,
    profile: profileProgress
  }
  dispatch(action)
}

export const updateActiveTab = (profileProgress: UserProfileStatus) => (dispatch: DispatchType) => {
  const action: profileProgressAction = {
    type: actionTypes.UPDATE_ACTIVE_TAB,
    profile: profileProgress
  }
  dispatch(action)
}

export const setDirtyForm = (profileProgress: UserProfileStatus) => (dispatch: DispatchType) => {
  const action: profileProgressAction = {
    type: actionTypes.SET_DIRTY_FORM,
    profile: profileProgress
  }
  dispatch(action)
}
