import { ChangeEvent } from 'react';
import classes from './RInputField.module.scss'


enum FieldSize {
  "small" = "210px",
  "large" = "450px",
}

type RInputFieldProps = {
  type?: string
  label?: string
  defaultValue?: any
  required?: boolean
  register: {}
  error?: any
  errorMessage?: string
  name: string
  instruction?: string
  size?: 'small' | 'large'
  hidden?: boolean
  textArea?: boolean
  placeholder?: string
  maxLength?: number
  value?: string
  privacy?: 'allow' | 'mask' | 'hidden' | 'mask-user-input'
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
};


const RInputField = ({type, label, defaultValue, value, name, onChange, errorMessage, register, error, textArea=false, hidden=false, required=false, size="large", placeholder, maxLength, instruction, privacy=undefined}: RInputFieldProps) => {

  return (
    <>
      {
        label &&
        <label className={classes.label}>
          {label}{required && "*"}
          {instruction && <small> ({instruction})</small>}
        </label>}
      {
        textArea ?
        <textarea
          style={{width: FieldSize[size]}}
          defaultValue={defaultValue}
          {...register}
          className={`form-control ${ error && 'is-invalid' } ${classes.field}`}
          rows={4}
          maxLength={maxLength}
          placeholder={placeholder}
          data-dd-privacy={privacy}
        /> :
        <input
          style={{width: FieldSize[size]}}
          defaultValue={defaultValue}
          type={type}
          hidden={hidden}
          value={value}
          {...register}
          className={`form-control ${ error && 'is-invalid' } ${classes.field}`}
          maxLength={maxLength}
          placeholder={placeholder}
          onChange={onChange}
          data-dd-privacy={privacy}
        />
      }
      {
        error && (
          (error.type === "required") ?
          (
            <div className="invalid-feedback">
              { errorMessage ? errorMessage : `${name} can't be blank.`}
            </div>
          ) :
          (
            <div className="invalid-feedback">
            { errorMessage ? errorMessage : `Please provide a valid ${name}.`}
            </div>
          )
        )
      }
    </>
  )
}

export default RInputField