import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from "react-redux"
import Authentication from '../Authentication'
import useAuthentication from '../../customHooks/useAuthentication'
import { toast } from 'react-toastify'
import { checkSession } from '../../helpers/session'
import SystemMessages from '../Pages/SystemMessages'
import TermsAndConditions from '../Pages/SystemMessages/TermsAndConditions'
import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum';

type PrivateRouteProps = {
  path: string,
  Component: React.FunctionComponent
}

const PrivateRoute = ({ path, Component }: PrivateRouteProps) => {
  const user: IUser = useSelector((state: UserState) => state.user)
  const credentials = JSON.parse(localStorage.getItem('credentials')!)
  const location = useLocation()
  const { signOut } = useAuthentication()

  useEffect(() => {
    if (checkSession()) {
      signOut()
      toast.info("Session has been expired, Sign in again.")
    }
  }, [location])

  if (user.authorized) {
    datadogLogs.setUser({ id: credentials.uid, email: user.email });
    datadogRum.setUser({ id: credentials.uid, email: user.email });
    return (user.tcAcknowledgementPending || user.smAcknowledgementPending) ? <SystemMessages tcAcknowledgementPending={user.tcAcknowledgementPending}  smAcknowledgementPending={user.smAcknowledgementPending}/> : <Component />
  } else {
    return <Authentication path={path} />
  }
}

export default PrivateRoute
