import { useLazyQuery } from '@apollo/client'
import { COUNTRY_LIST,
         REGION_LIST,
         LANGUAGE_LIST,
         PRACTICE_AREA_LIST,
         INDUSTRY_LIST,
         SOFTWARE_PRODUCT_LIST,
         YEAR_LIST,
         RESPONSIBILITY_LIST,
         STATE_LIST
       } from "../graphql/queries"
import { moveItemToEnd, moveItemToStart } from '../helpers/methods'
import { datadogLogs } from '@datadog/browser-logs';


const useOptions = () => {
  const [, {refetch: fetchCountryList}] = useLazyQuery(COUNTRY_LIST)
  const [, {refetch: fetchRegionList}] = useLazyQuery(REGION_LIST)
  const [, {refetch: fetchLanguageList}] = useLazyQuery(LANGUAGE_LIST)
  const [, {refetch: fetchPracticeAreaList}] = useLazyQuery(PRACTICE_AREA_LIST)
  const [, {refetch: fetchIndustryList}] = useLazyQuery(INDUSTRY_LIST)
  const [, {refetch: fetchSoftwareProductList}] = useLazyQuery(SOFTWARE_PRODUCT_LIST)
  const [, {refetch: fetchYearList}] = useLazyQuery(YEAR_LIST)
  const [, {refetch: fetchResponsibilityList}] = useLazyQuery(RESPONSIBILITY_LIST)
  const [, {refetch: fetchStateList}] = useLazyQuery(STATE_LIST)


  const getCountryList = (setCountryListData: React.Dispatch<React.SetStateAction<CountryList>>) => {
    datadogLogs.logger.info("Fetching country list...");
    
    fetchCountryList().then((response) => {
      datadogLogs.logger.info("Country list fetched successfully.");
      setCountryListData(response.data)
    })
  }

  const getRegionList = (countryId: string, setRegionListData: React.Dispatch<React.SetStateAction<RegionList>>) => {
    let logDetails: Record<string,any> = {
      countryId: countryId
    };
    datadogLogs.logger.info("Fetching region list...", logDetails);
    
    fetchRegionList({
      countryId: countryId
    }).then((response) => {
      let list = {
        regionList: [...response.data.regionList]
      }
      moveItemToStart(list.regionList, 'Region Not Listed')
      datadogLogs.logger.info("Region list fetched successfully.");
      setRegionListData(list)
    })
  }

  const getLanguageList = (setLanguageListData: React.Dispatch<React.SetStateAction<LanguageList>>) => {
    datadogLogs.logger.info("Fetching language list...");
    
    fetchLanguageList().then((response) => {
      let list = {
        languageList: [...response.data.languageList]
      }
      moveItemToEnd(list.languageList, 'Other')
      datadogLogs.logger.info("Language list fetched successfully.");
      setLanguageListData(list)
    })
  }

  const getPracticeAreaList = (setPracticeAreaListData: React.Dispatch<React.SetStateAction<PracticeAreaList>>) => {
    datadogLogs.logger.info("Fetching practice area list...");
    
    fetchPracticeAreaList().then((response) => {
      let list = {
        practiceAreasList: [...response.data.practiceAreasList]
      }
      moveItemToEnd(list.practiceAreasList, 'Unknown')
      datadogLogs.logger.info("Practice area list fetched successfully.");
      setPracticeAreaListData(list)
    })
  }

  const getIndustryList = (setIndustryData: React.Dispatch<React.SetStateAction<IndustryList>>) => {
    datadogLogs.logger.info("Fetching industry list...");
    
    fetchIndustryList().then((response) => {
      let list = {
        industryList: [...response.data.industryList]
      }
      moveItemToEnd(list.industryList, 'Unknown')
      datadogLogs.logger.info("Industry list fetched successfully.");
      setIndustryData(list)
    })
  }

  const getSoftwareProductList = (setSoftwareProductListData: React.Dispatch<React.SetStateAction<SoftwareProductList>>) => {
    datadogLogs.logger.info("Fetching software product list...");
    
    fetchSoftwareProductList().then((response) => {
      let list = {
        softwareProductList: [...response.data.softwareProductList]
      }
      moveItemToEnd(list.softwareProductList, 'Other')
      datadogLogs.logger.info("Software product list fetched successfully.");
      setSoftwareProductListData(list)
    })
  }

  const getYearList = (setYearListData: React.Dispatch<React.SetStateAction<YearList>>) => {
    datadogLogs.logger.info("Fetching year list...");
    
    fetchYearList().then((response) => {
      datadogLogs.logger.info("Year list fetched successfully.");
      setYearListData(response.data)
    })
  }

  const getResponsibilityList = (setResponsibilityListData: React.Dispatch<React.SetStateAction<ResponsibilityList>>) => {
    datadogLogs.logger.info("Fetching responsibility list...");
    
    fetchResponsibilityList().then((response) => {
      datadogLogs.logger.info("Responsibility list fetched successfully.");
      setResponsibilityListData(response.data)
    })
  }

  const getStateList = (countryName: string, setStateListData: React.Dispatch<React.SetStateAction<StateList>>) => {
    let logDetails: Record<string,any> = {
      countryName: countryName
    };
    datadogLogs.logger.info("Fetching state list...", logDetails);
    
    fetchStateList({
      countryName: countryName
    }).then((response) => {
      datadogLogs.logger.info("State list fetched successfully.");
      setStateListData(response.data)
    })
  }

  return {
    getCountryList,
    getRegionList,
    getLanguageList,
    getPracticeAreaList,
    getIndustryList,
    getSoftwareProductList,
    getYearList,
    getResponsibilityList,
    getStateList
  }
}

export default useOptions
