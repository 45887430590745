import { useEffect } from "react"
import { useSelector } from "react-redux"
import { useNavigate, useParams, useLocation } from "react-router-dom"
import useAuthentication from "../../customHooks/useAuthentication"
import SignIn from "./SignIn"
import SignUp from "./SignUp"

type AuthProps = {
  path?: string
}

const Authentication = ({ path }: AuthProps) => {
  const navigate = useNavigate()
  const routeParams = useParams()
  let location = useLocation()
  const user: IUser = useSelector((state: UserState) => state.user)
  const { signIn, signUp, verifyEmail } = useAuthentication()
  const isSignUp = '/signup' === location.pathname

  useEffect(() => { user.authorized && navigate(path ? path : '/') }, [user.authorized, navigate, path])

  useEffect(() => {
    routeParams.token && verifyEmail(routeParams.token)
  }, [routeParams.token])

  return (
    <div>
      {
        isSignUp ?
        <SignUp SignUpHandler={signUp} /> :
        <SignIn  SignInHandler={signIn} />
      }
    </div>
  )
}

export default Authentication