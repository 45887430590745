import { useEffect, useState } from 'react'
import PageHeading from '../../shared/PageHeading'
import classes from './Assessment.module.scss'
import { useNavigate } from 'react-router-dom'
import Button from '../../shared/ComponentLibrary/RButton'
import Icon from '../../../Icon'
import QuestionFeedback from './QuestionFeedback'
import { scrollToTop } from '../../../helpers/methods'


const PracticeAssessmentFeedback = ({ assessmentData }: {assessmentData: IAssessmentData}) => {
  const navigate = useNavigate()
  const [documentNumber, setDocumentNumber] = useState(0)
  const feedback = assessmentData.feedback

  console.log(assessmentData.feedback)
  const [documentData, setDocumentData] = useState(feedback && feedback[documentNumber].legalDocument)
  const [responsesData, setResponsesData] = useState(feedback && feedback[documentNumber].responses)

  useEffect(() => {
    setDocumentData(feedback && feedback[documentNumber].legalDocument)
    setResponsesData(feedback && feedback[documentNumber].responses)
    scrollToTop()
  }, [documentNumber])

  return (
    <div>
      {
        documentData &&
        <div>
          <div className={classes.header}>
            <PageHeading title='Practice Assessment Feedback' icon='testResults' />
            <Button
              innerText='Start Assessment'
              size='small'
              onClickMethod={() => navigate('/assessment/begin')}
            />
          </div>

          <div className={classes.assessment_section}>
            <div className={classes.title_section}>
              <div className={classes.title}>
                <b>Document {documentNumber + 1}:</b> "{ documentData.title }"
              </div>
            </div>
            <hr className={classes.divider}/>

            <div className={classes.questions_section}>
              <div className={classes.document_section}>
                <div className={classes.document}>
                  {
                    feedback && feedback[documentNumber].legalDocument.documentPages.map((page, index) => {
                      return (
                        <div key={index}>
                          <div>
                            <img src={page.imageUrl} alt={page.meta} />
                          </div>
                          <div>
                            <b>{page.meta}</b>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
              <div className={classes.questions}>
                {
                  assessmentData.assessment && assessmentData.assessment.questions.map((question) => {
                    return (
                      <div className={classes.question} key={question.id}>
                        <div className={classes.text}>
                          <b>{question.id}. </b>
                          <div>{question.question}</div>
                        </div>
                        {
                          responsesData && 
                          <QuestionFeedback
                            answer={responsesData[question.id - 1].answer}
                            correct={responsesData[question.id - 1].correct}
                            statement={responsesData[question.id - 1].statement}
                          />
                        }
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
          <div className={classes.feedback_buttons}>
            <div className={classes.back_button}>
              <Button
                type='button'
                variant='text'
                onClickMethod={() => setDocumentNumber((current) => current - 1)}
                hidden={documentNumber <= 0}
              >
                <Icon name='longArrowLeft' color='#2CB6D1' />
                Back
              </Button>  
            </div>
            <div className={classes.pagination}>
              {
                [0, 1, 2].map((index) => {
                  return (
                    <div
                      key={index}
                      className={documentNumber === index ? classes.active : classes.inactive}
                      onClick={() => setDocumentNumber(index)}
                    >
                      {index + 1}
                    </div>
                  )
                })
              }
            </div>
            <div className={classes.next_button}>
              <Button
                type='button'
                variant='text'
                onClickMethod={() => setDocumentNumber((current) => current + 1)}
                hidden={documentNumber >= 2}
              >
                Next
                <Icon name='longArrowLeft' color='#2CB6D1' transform />
              </Button>  
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default PracticeAssessmentFeedback