import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'


const Assessment = () => {
  const profileStatus: UserProfileStatus = useSelector((state: ProfileState) => state.profile)
  const navigate = useNavigate()

  useEffect(() => {
    if (!profileStatus.profileStatus) {
      navigate('/')
      toast.error('Profile Incomplete!')
    }
  }, [])

  return (
    <div>
      { profileStatus.profileStatus && <Outlet /> }
    </div>
  )
}

export default Assessment