import { iconsList } from "./iconsList";

type SVGProps = {
  name: keyof typeof iconsList
  size?: string
  color?: string
  transform?: boolean
}

const Icon = ({name, size='20', color, transform=false}: SVGProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      style={{transform: transform ?  'rotate(180deg)' : 'none'}}
      viewBox={iconsList[name].viewBox}
      fill={color}
    >
      {iconsList[name].path}
    </svg>
  );
};

export default Icon
