import { useMutation, useLazyQuery } from '@apollo/client'
import { setUserAction, logoutUserAction } from "../redux/actions/userActions"
import { useDispatch } from "react-redux"
import { Dispatch } from "redux"
import { useNavigate } from "react-router-dom"
import { SET_USER, CREATE_USER, USER_EMAIL_VERIFICATION, RESET_PASSWORD, CHANGE_PASSWORD } from "../graphql/mutations"
import { GET_USER_INFORMATION } from "../graphql/queries"
import { toast } from 'react-toastify'
import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum';


const useAuthentication = () => {
  const navigate = useNavigate()
  const dispatch: Dispatch<any> = useDispatch()
  const [authenticateUser] = useMutation(SET_USER)
  const [createUser] = useMutation(CREATE_USER)
  const [userEmailVerification] = useMutation(USER_EMAIL_VERIFICATION)
  const [resetUserPassword] = useMutation(RESET_PASSWORD)
  const [changeUserPassword] = useMutation(CHANGE_PASSWORD)
  const [, {refetch}] = useLazyQuery(GET_USER_INFORMATION)

  const signIn = ({attributes, setError}: ISignInData) => {
    datadogLogs.logger.info("Attempting to sign in...");
    
    authenticateUser({
      variables: {
        attributes: attributes
      }
    })
    .then((response) => {
      const authenticatedUser = response.data.authenticateUser
      let user: IUser = {
        authorized: true,
        firstName: authenticatedUser.user.firstName,
        lastName: authenticatedUser.user.lastName,
        email: authenticatedUser.user.email,
        tcAcknowledgementPending: authenticatedUser.user.tcAcknowledgementPending,
        smAcknowledgementPending: authenticatedUser.user.smAcknowledgementPending
      }
      console.log('authenticatedUser', authenticatedUser.credentials, authenticatedUser)
      if(authenticatedUser.credentials){
        let credentials: ICredentials = {
          authorizationToken: authenticatedUser.credentials.authorizationToken,
          client: authenticatedUser.credentials.client,
          uid: authenticatedUser.credentials.uid,
          expiry: authenticatedUser.credentials.expiry,
        }
  
        localStorage.setItem("credentials", JSON.stringify(credentials))
        datadogLogs.setUser({ id: authenticatedUser.credentials.uid, email: authenticatedUser.user.email });
        datadogRum.setUser({ id: authenticatedUser.credentials.uid, email: authenticatedUser.user.email });
        datadogLogs.logger.info("Signed in successfully.");
        dispatch(setUserAction(user))
      }else{
        console.log('test', response)
        navigate('/signin')
        if(authenticatedUser.confirmed){
        toast.info("Already confirmed user!. Please sign in.")
        }else{
        toast.info("Confirmation email sent. Please confirm your email to continue.")
        }
      }
      
    })
    .catch((error) => {
      console.error('Error SignIn:', error.graphQLErrors.map((e: any)=> e.message))
      setError && setError(error.graphQLErrors[0]?.message)
    })
  }

  const signUp = ({attributes, setError}: ISignupUserData) => {
    datadogLogs.logger.info("Attempting to sign up...");
    
    createUser({
      variables: {
        attributes: attributes
      }
    })
    .then((response) => {
      const createdUser = response.data.createUser

      let user: ISignupUser = {
        authorized: false,
        firstName: createdUser.firstName,
        lastName: createdUser.lastName,
        email: createdUser.email,
      }

      console.log(user)
      navigate('/signin')
      toast.info("A message with a confirmation link has been sent to your email address. Please follow the link to activate your account.")
      datadogLogs.logger.info("Signed up successfully.");
    })
    .catch((error) => {
      console.error('Error SignUp:', error.graphQLErrors.map((e: any)=> e.message))
      setError && setError(error.graphQLErrors[0].message)
    })
  }

  const signOut = () => {
    datadogLogs.logger.info("Signing out.");
    
    dispatch(logoutUserAction())
  }

  const verifyEmail = (confirmationToken: string) => {
    datadogLogs.logger.info("Verifying email...");
    
    userEmailVerification({
      variables: {
        confirmationToken: confirmationToken
      }
    }).then((response) => {
      const verificationStatus = response.data.confirmUserEmail
      let logDetails: Record<string,any> = {
        verificationStatusMessage: verificationStatus.message
      };
      
      if (verificationStatus.status) {
        datadogLogs.logger.info("Email verified successfully.", logDetails);
        toast.info(verificationStatus.message);
      } else {
        datadogLogs.logger.error("Email verification failed.", logDetails);
        toast.error(verificationStatus.message);
      }
    })
  }
  
  const requestResetPassword = (email: string) => {
    datadogLogs.logger.info("Requesting password reset...");
    
    resetUserPassword({
      variables: {
        email: email
      }
    }).then((response) => {
      const resetPasswordStatus = response.data.resetPassword
      navigate('/signin')
      datadogLogs.logger.info("Password reset requested successfully.");
      toast.info(resetPasswordStatus.message)
    })
  }

  const changePassword = (attributes: IResetPasswordData) => {
    datadogLogs.logger.info("Changing password...");
    
    changeUserPassword({
      variables: {
        attributes: attributes
      }
    })
    .then((response) => {
      console.log(response)
      const authenticatedUser = response.data.changePassword
      let user: IUser = {
        authorized: true,
        firstName: authenticatedUser.user.firstName,
        lastName: authenticatedUser.user.lastName,
        email: authenticatedUser.user.email,
        tcAcknowledgementPending: authenticatedUser.user.tcAcknowledgementPending,
        smAcknowledgementPending: authenticatedUser.user.smAcknowledgementPending
      }
      let credentials: ICredentials = {
        authorizationToken: authenticatedUser.credentials.authorizationToken,
        client: authenticatedUser.credentials.client,
        uid: authenticatedUser.credentials.uid,
        expiry: authenticatedUser.credentials.expiry,
      }

      localStorage.setItem("credentials", JSON.stringify(credentials))
      console.log(user)
      datadogLogs.logger.info("Password changed successfully.");
      dispatch(setUserAction(user))
    })
    .catch((error) => {
      console.error('Error Changing Password:', error.graphQLErrors.map((e: any)=> e.message))
      navigate('/signin')
      toast.error(error.graphQLErrors[0]?.message)
    })
  }

  const getUserInformation = (setProfileData: React.Dispatch<React.SetStateAction<UserProfile | undefined>>) => {
    datadogLogs.logger.info("Getting user information...");
    
    refetch().then((response) => {
      datadogLogs.logger.info("User information retrieved successfully.");
      console.log(response.data)
      setProfileData && setProfileData(response.data.user)
    })
    .catch((error) => {
      console.error('Error getting user information:', error, error.graphQLErrors.map((e: any)=> e.message))
      navigate('/signin')
      toast.error(error.graphQLErrors[0]?.message)
    })
  }

  return { signOut, signIn, signUp, getUserInformation, verifyEmail, requestResetPassword, changePassword }
}

export default useAuthentication
