interface TabPanelProps {
  children?: React.ReactNode
  index: string
  activeTab: string
}

function TabPanel({ children, activeTab, index, ...other }: TabPanelProps) {
  return (
    <div
      role="tabpanel"
      hidden={activeTab !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {activeTab === index && (
        children
      )}
    </div>
  )
}

export default TabPanel