import classes from './Assessment.module.scss'
import { useEffect, useState } from 'react';
import Icon from "../../../Icon"
import moment from 'moment';

type TimerProps = {
  seconds: number
  incremental?: boolean
  showIcon?: boolean
  resetTimer?: boolean
}

const Timer = ({ seconds, incremental, showIcon, resetTimer }:TimerProps) => {

  const [timeLeft, setTimeLeft] = useState(seconds)
  const [timedOut, setTimedOut] = useState(false)
  const [negativeTime, setNegativeTime] = useState(0)
  const [time, setTime] = useState(moment.utc(timeLeft * 1000).format('mm:ss'))

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft(incremental ? timeLeft + 1 : timeLeft - 1)

      if (timeLeft > 0) {
        setTime(moment.utc(timeLeft * 1000).format('mm:ss'))
      }
      else {
        setTimedOut(true)
        setNegativeTime(negativeTime + 1)
        setTime(moment.utc(negativeTime * 1000).format('mm:ss'))
      }
    }, 1000)

    return () => clearInterval(intervalId)
  }, [timeLeft])

  useEffect(() => {
    setTimeLeft(seconds)
    setTime(moment.utc(seconds * 1000).format('mm:ss'))
  }, [resetTimer, seconds])

  return (
    <div className={`${classes.timer} ${timedOut && classes.timed_out}`}>
      {showIcon && <Icon name='timer' color={timedOut ? '#ed7a7a' : '#2CB6D1'} size='32' />}
      {time}
    </div>
  )  
}

export default Timer