import { useState } from 'react'
import Icon from '../../../../Icon'
import classes from './ExpandableSection.module.scss'

interface ExpandableSectionProps {
  heading: string
  children?: React.ReactNode
  expand?: boolean
  onCloseHandler: () => void
  headingPrivacy?: 'allow' | 'mask' | 'hidden' | 'mask-user-input'
  project?:any
}

const ExpandableSection = ({heading, children, onCloseHandler, expand, headingPrivacy="allow", project}: ExpandableSectionProps) => {
  const [expanded, setExpanded] = useState(expand || false)

  return (
    <div className={classes.expandable_section}>
      <div className={classes.heading} data-dd-action-name='Expandable section'>
        <div className={classes.heading_content} data-dd-action-name='Expandable section'>
          <button type='button' className={expanded ? classes.arrow_up : classes.arrow_right} onClick={() => setExpanded((value) => !value)} data-dd-action-name='Expandable section dropdown'>
            <Icon name='sortDown' size='18' color='#025A66' transform={true}/>
          </button>
          <div
            data-dd-privacy={headingPrivacy}
            data-dd-action-name="Expandable section"
          >
          {project && (
              <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              className={`${classes.svgIcon} ${project.isValid ? classes.validProject : classes.invalidProject}`}
            >
                  <path
                    fill="currentColor"
                stroke="currentColor"
                strokeWidth="2" // Make the path bolder
                d={
                  project.isValid
                    ? 'M9 19.414L2.293 12.707l1.414-1.414L9 16.586 20.293 5.293l1.414 1.414z'
                    : 'M18.36 19.78L12 13.41 5.64 19.78 4.22 18.36 10.59 12 4.22 5.64 5.64 4.22 12 10.59 18.36 4.22 19.78 5.64 13.41 12 19.78 18.36z'
                }
              />
            </svg>
          )}
          {heading}
            
          </div>
        </div>
        <div>
          <button type='button' onClick={onCloseHandler}>
            <Icon name='trashIcon' size='24' color='red' />
          </button>
        </div>
      </div>
      <div className={expanded ? classes.content : classes.hide_content}>
        {children}
      </div>
    </div>
  )
}

export default ExpandableSection
