import { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'
import classes from './Assessment.module.scss'

type ProgressBarProps = {
  currentValue: number
  maxValue: number
}

const ProgressBar = ({currentValue, maxValue}: ProgressBarProps) => {

  const [value, setValue] = useState(Math.round(maxValue/currentValue))
  
  const BorderLinearProgress = styled(LinearProgress)(() => ({
    height: 15,
    borderRadius: 10,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: '#FFFFFF',
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: '#2CB6D1',
    },
  }))

  useEffect(() => setValue(Math.ceil((currentValue / maxValue) * 100)), [currentValue])
  
  return (
    <div className={classes.progress}>
      <div className={classes.progress_status}>Progress: {value}%</div>
      <BorderLinearProgress variant="determinate" value={value} />
    </div>
  )
}

export default ProgressBar
