import React, { useEffect } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import classes from './Authentication.module.scss'
import Button from '../shared/ComponentLibrary/RButton'
import InputField from '../shared/ComponentLibrary/RInputField'
import useAuthentication from '../../customHooks/useAuthentication'


const ResetPassword = () => {
  const navigate = useNavigate()
  const routeParams = useParams()
  const { requestResetPassword } = useAuthentication() 
  const user: IUser = useSelector((state: UserState) => state.user)
  const { register, handleSubmit, formState: { errors } } = useForm()

  const submitLogin: SubmitHandler<IFormInputs> = (data) => {
    requestResetPassword(data.email)
  }

  useEffect(() => {
    user.authorized && navigate('/')
  }, [routeParams.token])

  return (
    <div className={classes.authentication_container}>
      <div className={classes.heading} >
        <div className={classes.title}>Reset Password</div>
        <div className={classes.content}>Fill out the form below to reset your password. You will receive a verification email.</div>
      </div>
      
      <form className={classes.form} onSubmit={handleSubmit(submitLogin)} >
        <div className={classes.field}>
          <InputField
            name='email'
            label='Email'
            register={{...register('email', {
              required: true,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "invalid email address"
              }
            })}}
            error={errors.email}
            required
          />
        </div>

        <div className={classes.submit_button}>
          <Button type='submit' size='large' innerText='Reset Password' />
        </div>
      </form>
    </div>
  )
}

export default ResetPassword