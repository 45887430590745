import { useSelector } from "react-redux"
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import classes from './Header.module.scss'
import { useDispatch } from "react-redux"
import { Dispatch } from "redux"
import { setDirtyForm } from "../../../redux/actions/profileActions"
import useAuthentication from "../../../customHooks/useAuthentication"
import SmallButton from "../../shared/ComponentLibrary/RButton"
import SideDrawer from "../../Pages/Sidebar/SideDrawer"


const Header = () => {
  const user: IUser = useSelector((state: UserState) => state.user)
  const dispatch: Dispatch<any> = useDispatch()
  const profileStatus: UserProfileStatus = useSelector((state: ProfileState) => state.profile)
  const navigate = useNavigate()
  let location = useLocation()
  const { signOut } = useAuthentication()

  return (
    <div className={classes.header}>
      <div className={classes.header_background}></div>
      <div className={classes.header_content}>
        <div className={classes.logo_container}>
          <Link className="btn m-0 p-0" to="/" onClick={() => dispatch(setDirtyForm({...profileStatus, dirtyForm: false})) }>
            <img
              src={'/images/reviewright-logo-white-yellow.png'}
              className={classes.logo}
              alt="review-right-logo"
            />
          </Link>
        </div>
        <div>
          {
            user.authorized ?
            <div className={classes.greetings_container}>
              <span className={classes.greetings}>Hi, {user.firstName}!</span>
              <span className={classes.sign_out}>
                <SmallButton innerText="Sign Out" onClickMethod={signOut}/>
              </span>
              <SideDrawer />
            </div>
            : (
              location.pathname === '/signup' ?
              <SmallButton innerText="Sign In" onClickMethod={() => navigate('/signin')}/> :
              <SmallButton innerText="Sign Up" onClickMethod={() => navigate('/signup')}/>
            )
          }
        </div>
      </div>
    </div>
  )
}

export default Header