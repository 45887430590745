import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import classes from '../Home.module.scss'
import Button from '../../shared/ComponentLibrary/RButton'
import useTermsAndConditions from '../../../customHooks/useTermsAndConditions'

const TermsAndConditions = () => {
  const { getTermsAndConditions, createTcAcknowledgement } = useTermsAndConditions()
  const [termsAndConditions, setTermsAndConditionsData] = useState<TermsAndConditionsData>({})
  var HTMLContent: any;

  useEffect(() => {
    getTermsAndConditions(setTermsAndConditionsData)
  }, [])

  if(termsAndConditions) {
    HTMLContent = {__html: termsAndConditions.termsAndConditions?.content}
  }

  const updateProfile: any = () => {

    var acknowledgement: boolean = true
    createTcAcknowledgement(acknowledgement)
  }

  return (
    <div className={classes.sm_container}>
      <div className={classes.heading} >
        <div className={classes.title}>Terms and Conditions</div>
      </div>
      <div dangerouslySetInnerHTML={HTMLContent} className={classes.rich_text_html} />
      <div className={classes.form}>
        <div className='d-flex justify-content-center mt-4'>
          <Link to='/'>
            <Button size='large' innerText='Acknowledge' onClickMethod={updateProfile} />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default TermsAndConditions