export const isEmpty = (object: Object) => {
  return Object.keys(object).length === 0
}

export const scrollToTop = () => {
  document.body.scrollTop = 0 // For Safari
  document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
}

export const scrollDocToTop = (scrollRef: any) => scrollRef && scrollRef.current.scroll({ top: 0 })

export const moveItemToEnd = (array: any, elementName: string) => {
  array.push(array.splice(array.findIndex((element: any) => element.name === elementName), 1)[0]);
}

export const moveItemToStart = (array: any, elementName: string) => {
  array.unshift(array.splice(array.findIndex((element: any) => element.name === elementName), 1)[0]);
}
