import classes from './Sidebar.module.scss'
import { Link } from 'react-router-dom';
import Icon from '../../../Icon';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom'


interface TabProps {
  route: RouteType
  isActiveTab: boolean
  activateParentTab: (event: any) => void
  activeSubMenu: string
  setActiveSubMenu: (event: any, subMenu: string) => void
}

const Tab = ({route, isActiveTab, activateParentTab, activeSubMenu, setActiveSubMenu }: TabProps) => {
  const location = useLocation()
  const [expanded, setExpanded] = useState(isActiveTab)
  const [selectedSubMenu, setSelectedSubMenu] = useState(location.state?.from || activeSubMenu)
  const isActiveSubMenu = (path: string) => isActiveTab && selectedSubMenu === path

  useEffect(() => setExpanded(isActiveTab), [isActiveTab])

  const activateTab = (event: any, path: string) => {
    activateParentTab(event)
    setActiveSubMenu(event, path)
  }

  useEffect(() => setSelectedSubMenu(activeSubMenu), [activeSubMenu])

  return (
    <div className={classes.tab}>
      <div className={classes.route}>
        <Link className={`btn ${classes.tab}`} to={route.path} onClick={activateParentTab} >
          <div className={`${classes.route_name} ${isActiveTab && classes.selected}`}>
            <Icon name={route.icon} />
            <div>
              {route.name}
            </div>
          </div>
        </Link>
        <div className={classes.expand_icon}>
          {
            route.subRoutes &&
            <button className={expanded ? classes.arrow_up : undefined} onClick={() => setExpanded((value) => !value)}>
              <Icon name='sortDown' size='16' color='#025A66' />
            </button>
          }
        </div>
      </div>
      {
        route.subRoutes &&
        <div className={expanded ? classes.subRoutes : classes.hide_content} >
          {
            route.subRoutes?.map((subRoute, index) => (
              <Link key={index} to={route.path} state={{ from: subRoute.path }} onClick={(event) => activateTab(event, subRoute.path)}>
                <div className={classes.subRoute}>
                  <div className={`${classes.status} ${subRoute.isCompleted ? classes.complete : subRoute.isCompleted === false && classes.incomplete}`}></div>
                  <div className={isActiveSubMenu(subRoute.path) ? classes.selected : ''}>{subRoute.name}</div>
                </div>
              </Link>
            ))
          }
        </div>
      }
    </div>
  )
}

export default Tab