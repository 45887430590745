import * as actionTypes from "../actionTypes"
import { logoutSession } from "./../../helpers/session"
import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum';


export const setUserAction = (user: IUser) => (dispatch: DispatchType) => {
  const action: UserAction = {
    type: actionTypes.SET_USER,
    user,
  }
  dispatch(action)
}

export const logoutUserAction = () => (dispatch: DispatchType) => {
  const action: LogoutUserAction = {
    type: actionTypes.LOGOUT_USER
  }
  datadogLogs.clearUser();
  datadogRum.clearUser();
  logoutSession()
  dispatch(action)
}
